/* eslint-disable */
import React, { useRef } from "react";
import { COHATCH_MARKETS, COHATCH_LOCATIONS, MEMBERSHIP_TYPES } from "../../constant";
import html2pdf from "html2pdf.js";

function COhatchMembershipTermsDoc({ document }) {
    const DOCUMENT_TYPES_MAP = {
        MEAgreement: "Meeting & Event Agreement",
        HyggeCoworkingAgreement: "Hygge Coworking Agreement",
        COhatchMembershipTermsDoc: "Credit Card Authorization",
        COhatchMembershipTerms: "COhatch Membership Agreement"
    };
    const contentRef = useRef(null);


    // Helper function to get the display label
    const getDocumentTypeLabel = (documentType) => DOCUMENT_TYPES_MAP[documentType] || documentType;

    const getMembershipTypeLabel = (membershipTypeValue) => {
        // Find the matching membership type from MEMBERSHIP_TYPES array
        const membershipType = MEMBERSHIP_TYPES.find(item => item.value === membershipTypeValue);
    
        // Return the label if found, otherwise return the membershipTypeValue or fallback text
        return membershipType ? membershipType.label : membershipTypeValue || 'Not specified';
    };

    const getMarketLabel = (marketValue) => {
        const market = COHATCH_MARKETS?.market?.find(item => item.value === marketValue);
        return market ? market.label : marketValue;
    };

    const getLocationLabel = (marketValue, locationValue) => {
        // Loop through all cities in COHATCH_LOCATIONS
        for (const city of COHATCH_LOCATIONS.cities) {
            if (city[marketValue]) {  // Check if the city has this market
                const market = city[marketValue];
                const locations = market.locations || []; // Get locations for the market
                const location = locations.find(item => item.value === locationValue);

                if (location) {
                    return location.label;
                } else {
                    console.error(`Location not found for market: ${marketValue}, location: ${locationValue}`);
                    return locationValue; // Fallback to the original value
                }
            }
        }
        return marketValue; // Fallback if market is not found
    };

    const termsContent = [
        {
            header: ``,
            content: [
                <>All COhatch coworking memberships of whatever kind and type (generally for references purposes referred
                    to herein as a <strong>“Membership”</strong>) are subject to the following terms and conditions contained herein (referred to herein as these <strong>“Terms”</strong>). Each Member, by using any service or benefit offered by COhatch to such Member under such Member’s respective Membership Agreement, is agreeing to be bound by these Terms. COhatch may update these
                    Terms at any time upon notice to Member via the COhatch portal, the COhatch branded application, or any other
                    method of notice COhatch may deem appropriate in its discretion. Any capitalized terms not specifically defined in
                    these Terms shall have the same meaning as set forth in each Member’s respective Membership Agreement, the terms
                    of which are expressly incorporated herein by this reference.
                </>
            ]
        },
        {
            header: ``,
            content: [
                <p>
                    Hygge Coworking, LLC, a North Carolina limited liability company <strong>(“Hygge”)</strong> was recently acquired by
                    COhatch. As a result, Hygge is now owned and operated by COhatch, and all Hygge memberships are now COhatch
                    memberships. All references to COhatch herein shall be deemed to include Hygge.
                </p>,

                <p className="pt-2">
                    In order to book an after-hours social event - COhatch requires a credit card on file with the acceptance
                    of said addendum. Failure to comply will result in cancellation.
                </p>
            ]
        },

        {
            header: "1. Services.",
            content: [
                <>
                    COhatch will provide Member access to the services and amenities of the Home Location, including access to office space, internet access, office equipment, meeting rooms, common space, equipment rentals, physical activities, and knowledge resources (collectively, the <strong>“Services”</strong>) in a manner determined by COhatch, in its discretion. The Services are at all times subject to these Terms.
                </>
            ]
        },
        {
            header: `2. Reciprocity.`,
            content: [
                <>
                    Excluding any Memberships which limit Member’s access to one specific location, Member will receive coworking and common space reciprocity across all COhatch locations. Member may reserve meeting rooms, event space, and other amenities at any COhatch location, subject to Member’s agreement to pay additional fees in some instances upon making a reservation, and subject to Member’s agreement that some reservations may count against Membership plan hours.
                </>
            ]
        },
        {
            header: "3. Start-Up Fee.",
            content: [
                <>
                    Upon execution of the Membership Agreement, Member shall pay COhatch a onetime start-up fee (“Start-Up-Fee”) to cover keys and administrative set up costs incurred by COhatch.
                </>
            ]
        },
        {
            header: `4. Starter & Part-Time Coworking Memberships.`,
            content: [
                <>
                    Starter coworking memberships include 20 hours per month <strong>“Starter Coworking Membership”</strong> and Part-Time coworking memberships include 80 hours per month <strong>(“Part-Time Coworking Membership”)</strong>. COhatch reserves the right to invoice Member if Member exceeds the maximum amount of hours included in the Starter Coworking Membership or Part-Time Coworking Membership in any monthly period, as applicable. COhatch reserves the right to change the amount of hours in a Membership at any time.
                </>
            ]
        },
        {
            header: "5. Payment of Membership Fees.",
            content: [
                <>
                    Member must pay the Start-Up Fee and first month of Membership Fees (or full amount of the Membership Fees if the Initial Membership Term (as defined below) is less than one month) prior to the use of the Services. Membership Fees will be due on the first day of each and every month throughout the Membership Term. If the Membership Term does not start on the first day of the month, the Membership Fee for the first month will be prorated based on the number of days in the month in which the Membership Term commences. Start-Up Fee and Membership Fee payments shall be made my Member through the online COhatch portal via credit card or ACH. COhatch may agree to accept payment via physical check in some instances, at its sole discretion. All charges paid through the portal via credit card will incur a 3.5% convenience fee. Member is fully responsible for making all Membership Fee payments throughout the entire Membership Term. Sales tax will vary by state, and will be charged to the Member based upon residency and the Member’s Home Location.
                </>
            ]
        },
        {
            header: "6. Automatic Renewal.",
            content: [
                <>
                    The Member’s initial term of Membership in COhatch (referred to herein as the <strong>“Initial Membership Term”</strong>) shall be determined in accordance with the Member’s Membership Agreement. Initial Membership Terms which are shorter than one month shall automatically terminate at the end of such Initial Membership Term. For any Initial Membership Term lasting one month or longer, the Membership Term will automatically renew for an additional term equal to the Initial Membership Term contemplated in the Membership Agreement (each a <strong>“Renewed Term”</strong>), unless Member provides written notice of termination to COhatch at least 30 days before the expiration of the Initial Membership Term or Renewed Term, as applicable. The Initial Membership Terms under any Private Office Membership Agreements will automatically renew for an additional term equal to the Initial Membership Term as contemplated in the Private Office Membership Agreement (i.e. Month-to-Month, One Year, Two Year, or Custom Term), unless Member provides written notice of termination to COhatch at least 90 days before the expiration of the Initial Membership Term or Renewed Term, as applicable. For convenience, any reference in these Terms to a <strong>“Membership Term”</strong> shall include the Initial Membership Term and any Renewed Terms, as applicable.
                </>
            ]
        },
        {
            header: "7. Free Life Events.",
            content: [
                <>
                    Subject to COhatch’s sole discretion, space availability, and additional terms and conditions, Member is permitted to host certain free life events <strong>(“Free Life Events”)</strong> at COhatch during the Membership Term.
                </>
            ]
        },
        {
            header: `8. Membership Price Guarantee and Inflation:`,
            content: [
                <>
                    To provide stability for costs associated with Membership, Membership Fees will be frozen for month-to-month Coworking Memberships, Starter Coworking Memberships, Part-time Coworking Memberships, and unlimited coworking Memberships, and except for an annual adjustment for inflation each January, (which in any event shall not exceed $5 per month per Membership), will not be subject to any other annual increases.
                </>
            ]
        },
        {
            header: `9. Access & Keys.`,
            content: [
                <>
                    Member is generally entitled to 24-hour access, 7 days a week at the Home Location and will have a key to ensure accessibility, confidentiality, and security. Member is also able to access other COhatch locations, provided that Member goes to the desired location during normal business hours to complete orientation. COhatch reserves the right to change Member’s access to any COhatch location, including the Home Location, in its sole discretion. Member acknowledges and agrees that COhatch may periodically host private events at any of its locations during non-business hours (business hours are typically 8:00am – 5:00pm Monday to Friday, but may vary by location) and that Member’s access during such events may be restricted.
                </>
            ]
        },
        {
            header: "10. Guest Policy.",
            content: [
                <>
                    COhatch may allow Member to bring guests, including but not limited to its employees, agents, contractors or invitees (collectively, <strong>“Member Affiliates”</strong>) at Member’s own risk. Member may invite guests any time Member books a meeting room or event space. If Member does not book a meeting room or event space, guests are only permitted during staffed hours (unless Member receives written permission from the Community Manager) and are permitted to stay free of charge for up to one (1) hour. If Member does not book a meeting room or event space, and guests stay more than one (1) hour, Member must purchase a Guest Day Pass. Member is responsible for all charges accrued by a guest and COhatch has the right to bill the Member’s account directly for charges incurred by a guest. All guests must sign in upon entry. Guests are not intended to be re-occurring in nature and COhatch may restrict guests at any time.
                </>
            ]
        },

        {
            "header": "11. Replacement of Keys.",
            "content": [
                <>
                    If Member loses any of the keys provided by COhatch, Member may send an email to contact@cohatch.com and COhatch will arrange a replacement. A $10 key replacement fee will be charged to Member for each replacement magnetic key and a $100 key replacement fee will be charged to Member for each replacement key for a private office.
                </>
            ]
        },
        {
            "header": "12. Video Surveillance.",
            "content": [
                <>
                    Member acknowledges that areas of COhatch are under video surveillance and recording may be in progress. COhatch’s use of video surveillance is for safety purposes and to aid in the identification of individuals who are injured, behave in an unlawful or disruptive manner, or cause damage to property, and Member hereby agrees to being recorded while on site at any location operated or managed by COhatch. COhatch will not release video footage to anyone except, law enforcement or as a result of a court ordered subpoena.
                </>
            ]
        },
        {
            "header": "13. Government Issued Photo Identification.",
            "content": [
                <>
                    Member is required to present a valid (non-expired) government issued photo identification, such as a driver’s license or passport, to COhatch. COhatch will maintain a copy of Member’s photo identification throughout the duration of Member’s Membership Term. If a photo identification provided to COhatch expires during the Membership Term, COhatch may request Member to provide an updated government issued photo identification.
                </>
            ]
        },
        {
            "header": "14. Background Check.",
            "content": [
                <>
                    Member acknowledges that COhatch, or agents engaged by COhatch, may seek background information regarding Member that exists in the public record. <strong>Individuals with a felony conviction or registered sex offender status are not permitted to be a member. See Member Code of Conduct.</strong>
                </>
            ]
        },
        {
            "header": "15. Credit Authorization.",
            "content": [
                <>
                    COhatch reserves the right to seek credit information regarding Member in accordance with the Credit Check Certification and Authorization. Any and all terms contained in the Credit Check Certification and Authorization are hereby incorporated into these Terms by this reference
                </>
            ]
        },
        {
            "header": "16. Member Damage of Property.",
            "content": [
                <>
                    If Member or any of its Member Affiliates damage, destroy, or unlawfully use any of the personal, real, or any other type of property owned, leased, or managed by COhatch, Member shall reimburse COhatch for the full replacement or repair cost of the damaged, destroyed, or unlawfully used property within 5 calendar days of COhatch notifying Member of the replacement or repair cost thereof. Prior to the end of its Membership Term, Member(s) shall remove all personal property from the COhatch premises. Member authorizes COhatch to charge Member’s credit card on file with COhatch for the full replacement or repair cost of any COhatch property damaged, destroyed, or unlawfully used by Member or its Member Affiliates.
                </>
            ]
        },
        {
            "header": "17. Prohibited Uses.",
            "content": [
                <>
                    The Member and its Member Affiliates will not use the Services in any manner that violates any applicable law, rule, regulation or requirement of any governmental authority, the Membership Agreement, these Terms or any other reasonable rule (including Covid-19 or other local emergency temporary rules and guidelines), regulation or requirement that is established by COhatch. The Member and its Member Affiliates will not use the Services in any manner that could damage, disable, overburden, or impair any COhatch server, or interfere with any other party’s use and enjoyment of the Services. The Member and its Member Affiliates may not attempt to gain unauthorized access to any Services, accounts, computer systems, or networks connected to any COhatch server through hacking, password mining, or any other means not made explicitly and intentionally available by COhatch.
                </>,
                <p className="pt-2">
                    The Member hereby agrees it will NOT use the Services or the Membership for any of the following:
                </p>,

                <ul>
                    <li className="p-4">(a) In connection with contests, pyramid schemes, chain letters, junk email, spamming spimming, or any duplicative or unsolicited message in any form to defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights (such as the rights of privacy and publicity) of others.</li>
                    <li className="p-4">(b) Publishing, posting, uploading, distributing, or disseminating any inappropriate, profane, defamatory, obscene, indecent, or unlawful topic, name, material, or information on or through COhatch servers.</li>
                    <li className="p-4">(c) Uploading, or otherwise making available, files that contain images, photographs, software, pornography, or other material protected by intellectual property laws, including but not limited to, by way of example: copyright laws, trademark laws, and rights to privacy, unless Member controls the rights thereto or has all of the necessary consents to the same.</li>
                    <li className="p-4">(d) Using any material or information, including images or photographs, which are made available through the Services in any manner that infringes any copyright, trademark, patent, trade secret, or other proprietary right of any party.</li>
                    <li className="p-4">(e) Uploading any file that contains viruses, trojan horses, worms, time bombs, cancelbots, corrupted files, or any other similar software or programs that may damage the operation of another’s computer or property of another.</li>
                    <li className="p-4">(f) Downloading any file that the Member knows or reasonably should know cannot be legally reproduced, displayed, performed, or distributed in such a manner.</li>
                    <li className="p-4">(g) Restricting or inhibiting any other member from using or enjoying the Services.</li>
                    <li className="p-4">(h) Violating any law, or any code of conduct or other guidelines which may apply to any of the Services.</li>
                    <li className="p-4">(i) Using any COhatch location for residential purposes, such as sleeping.</li>
                    <li className="p-4">(j) Harvesting or otherwise collecting information about others, including email addresses, without the authorization or consent of the disclosing party.</li>
                    <li className="p-4">(k) Acting in any way that could reasonably be expected to injure the reputation of, be a nuisance, annoyance, or do damage to COhatch or any other member.</li>
                    <li className="p-4">(l) Performing crypto mining or any other action that consumes an abnormal amount of electricity. Any suspicious activity performed over a COhatch network or affecting network performance.</li>
                    <li className="p-4">(m) Creating or operating under a false identity.</li>
                </ul>,

                <p>
                    The determination that a Member (or any of its Member Affiliates) has wrongfully used the Services or the benefits of Membership in violation of this Section 19 shall be made exclusively by COhatch, at its discretion.
                </p>
            ]
        },
        {
            "header": "18. Disclosure to Authorities.",
            "content": [
                <>COhatch reserves the right to disclose any information that COhatch deems, in its sole and absolute discretion, necessary to satisfy any applicable law, regulation, legal process, or government request. COhatch may edit, refuse to post, or remove any information or materials from any physical or digital item that is connected to COhatch in its sole and absolute discretion.
                </>
            ]
        },
        {
            "header": "19. Confidentiality.",
            "content": [
                <>
                    Member acknowledges and agrees that during its participation in and use of the Services it may be exposed to Confidential Information. <strong>“Confidential Information”</strong> is any information, in whole or in part, disclosed by COhatch or any participant, Member, or user of the Services or any employee, affiliate, or agent thereof, which is nonpublic, confidential, or proprietary in nature. Confidential Information also includes, without limitation, information about business, sales, operations, know-how, trade secrets, technology, products, employees, customers, marketing plans, financial information, services, business affairs, any knowledge gained through examination or observation of or access to the facilities, computer systems and/or books and records of COhatch, any analyses, compilations, studies or other documents prepared by COhatch or otherwise derived in any manner from the Confidential Information and any information that the Member is obligated to keep confidential, or that the Member knows or has reason to know should be treated as confidential.</>,

                <p className="pt-4">
                    Member will maintain all Confidential Information in strict confidence. Member will not disclose Confidential Information or any derivative thereof to any individual, entity, or other type or organization at any time. This clause shall survive the termination of these Terms. All Confidential Information remains the sole and exclusive property of the disclosing party. Nothing in these Terms or in the Services will be construed to give a Member any rights to Confidential Information of any kind.
                </p>
            ]
        },
        {
            "header": "20. Disclaimer of Warranties.",
            "content": [
                <>
                    To the maximum extent permitted by law, COhatch provides the Services “as is” together with all faults and hereby disclaims any and all warranties and conditions, express, implied, or statutory. The entire risk as to the quality, condition, accuracy, and completeness of the Services remains with the Member.
                </>
            ]
        },
        {
            "header": "21. Assumption of Risk.",
            "content": [
                <>
                    COhatch may provide physical activities in connection with the Services, including, but not limited to, rock climbing, golf and bounce houses (the <strong>“Physical Activities”</strong>). Member acknowledges and agrees that participation in Physical Activities comes with inherent risks including the risks of injury, disability, and death. Member also acknowledges and agrees that participating in Physical Activities during unsupervised times increases and enhances certain risks. Knowing and appreciating all of these risks, Member knowingly and intelligently determines to expressly assume all risks association with Physical Activities, whether caused by the ordinary negligence of COhatch, other releasees, other participants or otherwise, and understands that its participation in Physical Activities is voluntary. Member is solely responsible for ensuring that its Member Affiliates understand the inherent risks associated with the Physical Activities and are physically sound (not suffering from any condition, impairment, disease, infirmity, or other illness) prior to participating in Physical Activities.
                </>
            ]
        },
        {
            "header": "22. Limitation on Damages.",
            "content": [
                <>
                    To the maximum extent permitted by applicable law, in no event shall COhatch, its affiliates, divisions, and their past, present and future officers, agents, shareholders, members, representatives, employees, successors and assigns, jointly or individually, be liable for any direct, general, special, incidental, indirect, punitive, consequential, or other damages whatsoever (including, but not limited to, damages for: loss of profits, loss of Confidential Information, business interruption, personal injury, loss of privacy, failure to meet any duty including good faith or reasonable care, negligence, and any other pecuniary or other loss whatsoever) in any way related to the participation in or inability to participate in or use of the Services, including Physical Activities, the provision of or failure to provide Services, or otherwise in connection with any provision of these Terms, even if the fault is in tort (including negligence), strict liability, breach of contract, or breach of warranty of COhatch, and even if COhatch has been advised of the possibility of such damages. All Member equipment, materials, supplies, and all other property brought to COhatch shall be at Member’s risk and COhatch shall not be responsible for any damage, loss, or theft of the property.
                </>,

                <p className="pt-4">
                    Member’s exclusive remedy for all of the foregoing shall be limited by actual damages incurred by Member up to $1,000. The foregoing limitations, exclusions, and disclaimers (including the disclaimer of warranties above) shall apply to the maximum extent permitted by applicable law, even if any remedy fails its essential purpose.
                </p>
            ]
        },
        {
            "header": "23. Early Termination by COhatch.",
            "content": [
                <>
                    COhatch reserves the right to terminate, at any time and in its sole and absolute discretion, the Membership Agreement or any of the Services, provided it gives Member express written notice that its Membership, or the specified Service, will be terminated. If COhatch terminates the Membership Agreement or any of the Services for any reason other than an Event of Default, COhatch agrees to return any Membership Fees paid in advance by Member.
                </>
            ]
        },
        {
            "header": "24. Events of Default and Remedies.",
            "content": [
                <>
                    Each of the following constitutes an <strong>“Event of Default”</strong> under the Membership Agreement: (a) Member fails to pay any Membership Fee or other monetary obligation under the Membership Agreement when due and such failure continues for a period of 15 days; (b) Member breaches any COhatch code of conduct; (c) Member engages in any of the Prohibited Uses identified in Section 19; or (d) Member engages in any act that violates and/or infringes upon rights of others, damages the reputation of COhatch, constitutes gross negligence, or seriously and/or materially breaches these Terms.
                </>,
                <p className="pt-4 pl-8">
                    24.1. Upon the occurrence of any Event of Default, COhatch may, without terminating Member’s obligation to pay Membership Fees or other amounts payable by Member under the Membership Agreement, elect to terminate the Membership Agreement and the Member’s participation in and use of the Services by giving notice to Member, which termination shall be effective as of the date of such notice or any later date specified by COhatch in such notice (and on the effective date of such termination, all obligations and liabilities of COhatch under the Membership Agreement shall terminate), and without further notice, COhatch shall have the right to remove Member and all other persons and property from the COhatch premises, without liability for damage to, and without obligation to store, such property. No action or omission by COhatch shall be construed as an election by COhatch to terminate the Membership Agreement, nor shall the same operate to release the Member in whole or in part from any of Member’s obligations hereunder, unless express written notice of such intention is delivered by COhatch to Member.
                </p>,
                <p className="pt-4 pl-8">
                    24.2. COhatch shall be entitled to recover all losses and damages it incurs as a result of its termination of the Membership Agreement upon an Event of Default, including without limitation: (1) accrued Membership Fees to the effective date of termination together with late charges and interest thereon at the rate of 18% per annum (the <strong>“Default Rate”</strong>) from the date owed through the date paid; plus (2) all costs of enforcing the Membership Agreement against Member; plus (3) the present value of the Membership Fees, discounted at a rate of interest equal to 8% per annum (the <strong>“Discount Rate”</strong>), that would have accrued under the Membership Agreement during the term of the Membership Agreement, reduced by the present value of the actual Membership Fees, discounted at the Discount Rate; plus (4) any other costs or amounts necessary to compensate COhatch for all damages resulting from Member’s failure to perform its obligations under the Membership Agreement. If a court of competent jurisdiction determines that the Default Rate has exceeded the maximum rate allowed by law, the excess of the amount collected over the legal rate of interest will be applied to any amount owed, retroactively, as of the date of receipt.
                </p>,

                <p className="pt-4 pl-8">
                    COhatch’s pursuit of any of the foregoing remedies shall not preclude its pursuit of any other remedies provided in the Membership Agreement or otherwise provided by law, nor shall its pursuit of any remedy in the Membership Agreement forfeit or waive any Membership Fees due to COhatch or any damage accruing to COhatch by reason of the violation of any of these Terms, provisions and covenants contained in the Membership Agreement. COhatch’s failure or delay to enforce one or more of the remedies provided herein upon an Event of Default shall not be deemed or construed to constitute a waiver of such Event of Default. COhatch may bring suit for any amounts owed by Member under the Membership Agreement as the same accrue or after the same have accrued, and no suit or recovery of any such amount shall be deemed to waive COhatch’s right to collect all amounts to which COhatch is entitled hereunder, nor shall the same serve as any defense to any subsequent suit brought for any amount not theretofore reduced to judgment. COhatch shall have no obligation to observe or perform any provision of the Membership Agreement on its part to be observed or performed which accrues after the date of any uncured Event of Default by Member. The times set forth herein for the curing of violations by Member are of the essence.
                </p>
            ]
        },
        {
            "header": "25. Interest and Late Charges.",
            "content": [
                <>
                    Any Membership Fees not paid when due shall bear interest beginning 15 days after the due date at the Default Rate. In addition, in the event COhatch does not terminate the Membership Agreement under Section 26.1, Member shall pay COhatch a late charge equal to 10% of the amount 15 days overdue to compensate COhatch for the extra costs COhatch will incur as a result of such late payment. The parties agree that (a) it would be impractical and extremely difficult to fix the actual damage that COhatch will suffer on account of Member’s late payment, (b) such interest and late charges represent a fair and reasonable estimate of the detriment that COhatch will suffer by reason of a late payment by Member, and (c) the payment of interest and late charges are distinct and separate in that the payment of interest is to compensate COhatch for the use of COhatch’s money by Member, while the payment of late charge is to compensate COhatch for COhatch’s processing, administrative and other costs incurred by COhatch as a result of Member’s delinquent payments. Acceptance by COhatch of any Membership Fees, late charges and/or interest payments following the date when due by Member shall not be deemed consent by COhatch to late payments, nor a waiver of COhatch’s right to insist upon timely payments at any time, nor a waiver of any remedies to which COhatch is entitled as a result of the late payment of Membership Fees.
                </>
            ]
        },
        {
            "header": "26. Non-Disparagement.",
            "content": [
                <>
                    During and after the Membership Term, Member will refrain from making any statements of a defamatory or disparaging nature to any third party or the general public regarding COhatch or any of its owners.
                </>
            ]
        },
        {
            "header": "27. Indemnification.",
            "content": [
                <>
                    Member releases, and hereby agrees to indemnify, defend and hold harmless COhatch, its affiliates, divisions, and their past, present and future officers, agents, shareholders, members, representatives, employees, successors and assigns from and against, and will reimburse such indemnified parties for, all claims, liabilities, losses, damages, costs, expenses, judgments, fines and penalties based upon or arising out of or in connection with (a) the actions, errors, omissions, negligence, willful misconduct or fraud of Member or any of its Member Affiliates, (b) any breach or default in the performance of any of Member’s obligations under any of the agreements, terms, covenants or conditions of the Membership Agreement or these Terms, including without limitation the Code of Conduct set forth herein or (c) any incident that occurs in connection with the Services, including Physical Activities, which results in personal injury to Member or its Member Affiliates unless resulting from the negligent acts or omissions of COhatch; (d) any incident that occurs in connection with alcohol consumed at any COhatch location or space which results in personal injury to Member or its Member Affiliates or any third party. Member further agrees in the event that it brings a claim or lawsuit in violation of these Terms, it shall be liable for any attorneys' fees and costs incurred by COhatch or its respective officers, agents, or owners in connection with the defense of such claim or lawsuit.
                </>
            ]
        },
        {
            "header": "28. Member & Employee Behavior.",
            "content": [
                <>
                    Member is responsible for the behavior of its Member Affiliates, including their compliance with applicable laws, rules, regulations and requirements of any governmental authority, the requirements of the Membership Agreement and these Terms and any other reasonable rule, regulation or requirement that is established by COhatch and communicated in writing to the Member. Member is responsible for its Member Affiliates’ compliance with all applicable laws, rules and regulations concerning alcohol, tobacco and other controlled substances and is liable for enforcing responsible and legal use of the same. If COhatch determines, in its sole and absolute discretion, that the behavior of any Member Affiliate does not comply with the Membership Agreement and the Code of Condect set forth herein, and any other reasonable rule, regulation or requirement that is established by COhatch and communicated in writing to the Member, COhatch may terminate this Agreement immediately, and/or prohibit such Member or Member Affiliate access to the Member’s Home Location and any other COhatch space or location. COhatch may execute any remedies available under these Terms or by applicable law in its sole and absolute discretion.
                </>
            ]
        },
        {
            "header": "29. Member Code of Conduct.",
            "content": [
                <>
                    COhatch is committed to providing a safe and welcoming environment for all members, guests and team members.promote safety and comfort for all, all individuals are asked to act appropriately at all times when in our locations. We expect persons using COhatch to act maturely, to behave responsibly, and to respect the rights and dignity of others (other members, guests, COhatch staff). The following policies articulate COhatch’s values and expectations for acceptable and appropriate behavior (the <strong>“Code of Conduct”</strong>):
                </>,
                <p className="pt-4 pl-8">
                    29.1. <strong>Alcohol.</strong> For any alcohol consumed on COhatch property, Member agrees to drink responsibly. Member is solely responsible for ensuring that its Member Affiliates are at least 21 years or older prior to serving them alcohol on COhatch premises and ensuring that its Member Affiliates drink responsibly. Member agrees that it is solely responsible for any and all damages suffered by Member or its Member Affiliates or caused by Member or its Member Affiliates to COhatch or any other third parties as a result of alcohol consumption on any COhatch premises including, but not limited to, property damage, personal injury, death, disfigurement, or violation of any applicable laws.`
                </p>,

                <p className="pt-4 pl-8">
                    29.2. <strong>Weapon Policy.</strong> COhatch is a weapons free environment. Member agrees and acknowledges that neither Member nor any its Member Affiliates are permitted to possess or carry a firearm in facilities operated or managed by COhatch, unless otherwise approved by COhatch's third party safety team (Safe Passage Consulting). Open carry of any weapons is otherwise prohibited unless they are uniformed on-duty law enforcement. Members are responsible to ensure all Member Affiliates are aware of, and in full compliance with COhatch’s weapon policy.`
                </p>,

                <p className="pt-4 pl-8">
                    29.3. <strong>Drugs. Smoking/Vaping.</strong> COhatch is a drug free environment. Smoking or vaping any substance inside a COhatch location is strictly prohibited.
                </p>,

                <p className="pt-4 pl-8">
                    29.4. <strong>Prohibited Persons.</strong> Any person who has been accused or convicted of any crime involving sexual abuse, is or has been a registered sex offender is not permitted to be a member.
                </p>,

                <p className="pt-4 pl-8">
                    29.5. <strong>General Behavior.</strong> The following behaviors are prohibited:
                </p>,
                <ul>
                    <li className="pt-4 pl-12">• Abusive, discriminatory, derogatory, or demeaning speech or actions;</li>
                    <li className="pt-4 pl-12">• Physical contact with another person in an angry, aggressive or threatening way</li>
                    <li className="pt-4 pl-12">• Harassing behavior such as: offensive verbal comments related to gender, sexual orientation, race, religion, national origin, gender identity, disability, or other demographic characteristics of members or guests;</li>
                    <li className="pt-4 pl-12">• Inappropriate use of nudity and/or sexual images at COhatch spaces or events;</li>
                    <li className="pt-4 pl-12">• Deliberate intimidation, including virtual or in-person stalking by words, gestures, body language, or any type of menacing behavior;</li>
                    <li className="pt-4 pl-12">• Yelling at or threatening speakers, members, staff, or guests;</li>
                    <li className="pt-4 pl-12">• Sustained disruption of meetings or events;</li>
                    <li className="pt-4 pl-12">• Physical contact that is uninvited and, in the situation, inappropriate, and unwelcome or coercive sexual attention;</li>
                    <li className="pt-4 pl-12">• Sexually explicit conversation or behavior: any sexual contact with another person;</li>
                    <li className="pt-4 pl-12">• Persisting in behavior directed at another after being informed the behavior is unwelcome or offensive;</li>
                    <li className="pt-4 pl-12">• Theft or behavior that results in the destruction or loss of property; or</li>
                    <li className="pt-4 pl-12">• Retaliation against individuals who raise conduct concerns.</li>
                </ul>,

                `The determination that a Member (or any of its Member Affiliates) has violated the Code of Conduct set forth in this
Section 29 shall be made exclusively by COhatch, at its discretion.`

            ]
        },
        {
            "header": "30. Commercial Transaction.",
            "content": [
                <>
                    Member acknowledges and agrees that this Membership Agreement was made for commercial or business purposes
                </>
            ]
        },
        {
            "header": "31. Severability.",
            "content": [
                <>In the event that any provision or portion of these Terms are determined to be invalid, illegal, or unenforceable for any reason, in whole or in part, said provision shall be reformed so as to best effectuate the intent of these Terms and the remaining provisions of these Terms shall be unaffected thereby and shall remain in full force and effect to the fullest extent permitted by applicable law.
                </>
            ]
        },
        {
            "header": "32. Authority.",
            "content": [
                <>
                    Member has all requisite legal authority to enter into and abide by these Terms without any further authorization or approval. Member’s participation or use in the Services is of its free will and does not violate or breach any license, contract, instrument, or any other obligation to which Member is a party.
                </>
            ]
        },
        {
            "header": "33. Waiver.",
            "content": [
                <>
                    The failure or delay by COhatch to enforce or exercise any of its rights or remedies herein shall not be construed as a waiver hereof, nor affect the ability of COhatch to enforce any of COhatch’s rights and remedies herein. No waiver of any Member default by COhatch shall be held to be a waiver of any other default.
                </>
            ]
        },
        {
            "header": "34. Assignment.",
            "content": [
                <>
                    Member shall not assign, delegate or otherwise transfer its Membership or any of its rights or obligations under the Membership Agreement without COhatch’s prior written consent, which consent shall be based upon COhatch’s sole and absolute discretion. COhatch reserves the right to assign, delegate or otherwise transfer any of its rights or obligations under the Membership Agreement without Member’s consent.
                </>
            ]
        },
        {
            "header": "35. Governing Law.",
            "content": [
                <>
                    The Membership Agreement shall be governed by and shall be construed in accordance with the laws of the State of Ohio without regard to application of choice-of-law rules or principles. The parties hereby agree to the exclusive jurisdiction and venue of the state and federal courts located in Franklin County, Ohio.
                </>
            ]
        },
        {
            "header": "36. Entire Agreement and Modification.",
            "content": [
                <>
                    The Membership Agreement, including any and all Addendums, and these Terms supersede all prior agreements, whether written or oral, between the parties with respect to its subject matter and constitutes a complete and exclusive statement of the terms of the agreement between the parties with respect to its subject matter. Except as otherwise specifically provided herein, the Membership Agreement may not be amended, supplemented, or otherwise modified except by a written agreement executed by the party to be charged with the amendment.
                </>
            ]
        }
    ];

    const downloadPDF = () => {
        const element = contentRef.current;
        if (element) {
            const options = {
                margin: 0.1,
                filename: "document.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: {
                    scale: 4,
                    useCORS: true,
                    onerror: (err) => {
                        console.error("Error loading images: ", err);
                        alert("An error occurred while loading images. Please check the console.");
                    },
                },
                jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            };

            html2pdf().from(element).set(options).save();
        } else {
            console.error("Element not found");
        }
    };


    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10 px-5">
            <div className="bg-white shadow-lg rounded-lg w-full max-w-7xl p-6 mb-10">
                <div className="items-center">
                    <div className="">
                        <img className="mx-auto mt-4 mb-4 h-auto w-36" src={"https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/static/images/COhatch_hygge%20logos_black.png"} alt=""></img>
                    </div>
                </div>
                <h2 className="text-4xl font-semibold mb-6 text-center text-primary-400">{getDocumentTypeLabel(document.documentType)}</h2>
                <div className="space-y-6 mb-6">
                    <div className="items-center max-w-5xl container p-4 mx-auto">
                        {/* Home Market and Home Location */}
                        <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-4 justify-items-center">
                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Home Market:</h3>
                                <p className="font-normal">
                                    {document?.meetingLocation?.market ? getMarketLabel(document.meetingLocation.market) : 'Not specified'}
                                </p>
                            </div>
                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Home Location:</h3>
                                <p className="font-normal">
                                    {document?.meetingLocation?.location ? getLocationLabel(document.meetingLocation.market, document.meetingLocation.location) : 'Not specified'}
                                </p>
                            </div>

                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Name:</h3>
                                <p className="font-normal">{document.firstName} {document.lastName}</p>
                            </div>
                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Company Name:</h3>
                                <p className="font-normal">{document.companyName}</p>
                            </div>
                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Date of Birth:</h3>
                                <p className="font-normal">
                                    {new Date(document.membershipTerms?.dateOfBirth).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                </p>
                            </div>
                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Primary Phone:</h3>
                                <p className="font-normal">{document.primaryPhone}</p>
                            </div>

                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Email:</h3>
                                <p className="font-normal">{document.emailAddress}</p>
                            </div>
                        </div>

                        {/* Membership Package Title */}
                        <div className="col-span-full text-center my-6">
                            <h2 className="font-semibold text-2xl text-primary-400">MEMBERSHIP PACKAGE</h2>
                        </div>

                        <div className="text-center grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-4 justify-items-center">
                            {/* Membership Details */}
                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Membership Type:</h3>
                                <p className="font-normal">
                                    {document?.membershipTerms?.membershipType ? getMembershipTypeLabel(document.membershipTerms.membershipType) : 'Not specified'}
                                </p>

                            </div>

                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Membership Start Date:</h3>
                                <p className="font-normal">
                                    {new Date(document.membershipTerms?.membershipStartDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                </p>
                            </div>

                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Initial Term:</h3>
                                <p className="font-normal">{document?.membershipTerms?.initialMembershipTerm || 'Not specified'}</p>
                            </div>

                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Private Office:</h3>
                                <p className="font-normal">{document?.membershipTerms?.assignedPrivateOffice || 'Not assigned'}</p>
                            </div>

                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Non-Refundable Fee:</h3>
                                <p className="font-normal">
                                    {document?.membershipTerms?.nonRefundableFee ? `$${document.membershipTerms.nonRefundableFee}` : 'Not specified'}
                                </p>
                            </div>

                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Office Deposit:</h3>
                                <p className="font-normal">
                                    {document?.membershipTerms?.officeDeposit ? `$${document.membershipTerms.officeDeposit}` : 'Not specified'}
                                </p>
                            </div>

                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Total Due at Start:</h3>
                                <p className="font-normal">
                                    {document?.membershipTerms?.totalDueStart ? `$${document.membershipTerms.totalDueStart}` : 'Not specified'}
                                </p>
                            </div>

                            <div className="flex flex-col items-center space-y-2">
                                <h3 className="font-semibold text-lg">Total Due Monthly:</h3>
                                <p className="font-normal">
                                    {document?.membershipTerms?.totalDueMonthly ? `$${document.membershipTerms.totalDueMonthly}` : 'Not specified'}
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className="space-y-6">
                        {termsContent.map((term, index) => (
                            <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                                {/* Display Header in bold, then content in regular font, both on the same line */}
                                <div className="text-gray-700">
                                    <span className="font-semibold text-xl text-primary-600 inline">{term.header} </span>
                                    <span>
                                        {term.content.map((item, idx) => {
                                            if (Array.isArray(item)) {
                                                // Handle if it's an array of items (bullet list)
                                                return (
                                                    <ul key={idx} className="list-disc pl-6 mt-4">
                                                        {item.map((point, pIdx) => (
                                                            <li key={pIdx}>{point}</li>
                                                        ))}
                                                    </ul>
                                                );
                                            } else if (typeof item === 'string') {
                                                // Check if it's the specific section with both a paragraph and bullet list (like 29.5)
                                                if (term.header === "29") {
                                                    return (
                                                        <div key={idx} className="pl-8 py-2">
                                                            {/* Render the paragraph */}
                                                            <p>{item}</p>

                                                            {/* Render bullet points (if next content item is an array) */}
                                                            {term.content[idx + 1] && Array.isArray(term.content[idx + 1]) && (
                                                                <ul className="list-disc pl-6 mt-4">
                                                                    {term.content[idx + 1].map((point, pIdx) => (
                                                                        <li key={pIdx}>{point}</li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                                // Handle standard paragraph
                                                return (
                                                    <div key={idx} className={`pl-8 ${idx > 0 ? 'mt-4' : ''} py-2`}>
                                                        <span>{item}</span>
                                                    </div>
                                                );
                                            } else {
                                                return <span key={idx}>{item}</span>;
                                            }
                                        })}
                                    </span>
                                </div>

                                {/* Display Initials if available */}
                                {document?.initials && document.initials.length > 0 ? (
                                    <div>
                                        {document.initials
                                            .filter((initial) => {
                                                // Filter initials based on section (section5 or section6) for the correct index
                                                return (index === 15 && initial.section === 'section14');
                                            })
                                            .map((initial, index) => (
                                                <p key={index}>
                                                    <h3 className="font-semibold text-lg mt-6">Initials:</h3>
                                                    Your Initials: {initial.value}
                                                </p>
                                            ))}
                                    </div>
                                ) : (
                                    (index === 15) && (
                                        <div className="space-y-4 mt-4">
                                            <h4 className="font-semibold text-lg">Initials</h4>
                                            <p className="text-danger-500">Intials not yet submitted by the user:</p>
                                        </div>
                                    )
                                )}
                            </div>
                        ))}
                    </div>

                    {/* Memebr Information Section */}
                    <div className="space-y-4">
                        {document?.signature ? (
                            // Show the data fields (membership terms) if signature is available
                            <>
                                {/* Displaying Membership Data */}
                                {document.membershipTerms && (
                                    <div className="max-w-4xl mx-auto p-8 bg-gray-50 rounded-lg shadow-md mb-6">
                                        {/* Member: */}
                                        <div className="text-center mb-6">
                                            <h3 className="text-3xl font-semibold text-gray-800 flex items-center justify-center gap-2">
                                                Member:
                                            </h3>
                                        </div>

                                        {/* Display Membership Terms */}
                                        <div className="text-center mb-6">
                                            <div className="text-lg font-semibold text-success-800">Company Name</div>
                                            <p className="text-gray-600">{document.membershipTerms.memberCompanyName}</p>
                                        </div>

                                        <div className="text-center mb-6">
                                            <div className="text-lg font-semibold text-success-800">Print Name</div>
                                            <p className="text-gray-600">{document.membershipTerms.printName}</p>
                                        </div>

                                        <div className="text-center mb-6">
                                            <div className="text-lg font-semibold text-success-800">Print Title</div>
                                            <p className="text-gray-600">{document.membershipTerms.printTitle}</p>
                                        </div>

                                        <div className="text-center mb-6">
                                            <div className="text-lg font-semibold text-success-800">Member Signature Date</div>
                                            <p className="text-gray-600">{document.membershipTerms.signatureDate}</p>
                                        </div>

                                        {/* Display Mailing Address */}
                                        {document.membershipTerms.address && (
                                            <div className="text-center mb-6">
                                                <div className="text-lg font-semibold text-success-800">Mailing Address</div>
                                                <p className="text-gray-600">{document.membershipTerms.address.mailingStreetAddress}</p>
                                                <p className="text-gray-600">{document.membershipTerms.address.city}, {document.membershipTerms.address.state} {document.membershipTerms.address.zip}</p>
                                            </div>
                                        )}
                                    </div>
                                )}

                            </>
                        ) : (
                            <>
                                <div className="max-w-4xl mx-auto p-4 bg-gray-50 rounded-lg shadow-md">
                                    {/* Payment Information Title */}
                                    <div className="text-center">
                                        <h3 className="text-2xl font-semibold text-danger-500">
                                            PAYMENT INFORMATION - Not yet submitted by the user
                                        </h3>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>


                    <div className="flex flex-col sm:flex-row sm:gap-8 justify-center mt-2">
                        {/* Signature Canvas Section */}
                        <div className="flex-1 sm:w-[55%]">
                            {document?.signature ? (
                                <div className="max-w-lg mx-auto p-2 bg-gray-50 rounded-lg shadow-md">
                                    <h4 className="text-2xl font-semibold text-center text-primary-400 mb-4">Signature Preview</h4>
                                    <div className="flex justify-center items-center p-4 bg-white border border-gray-200 rounded-lg">
                                        <img
                                            src={document.signature}
                                            alt="Signature Preview"
                                            className="max-w-full h-auto rounded-md"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="max-w-4xl mx-auto p-4 bg-gray-50 rounded-lg shadow-md">
                                    <div className="text-center">
                                        <h3 className="text-2xl font-semibold text-danger-500">
                                            SIGNATURE - Not yet submitted by user.
                                        </h3>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2 text-center">
                <button onClick={downloadPDF} className="mt-4 bg-primary-400 text-white py-2 px-4 rounded">
                    Download PDF
                </button>
            </div>
        </div>
    );
}

export default COhatchMembershipTermsDoc;
