/* eslint-disable */
import React from 'react';
import SelectInput from '../select_input/SelectInput.jsx';
import { COHATCH_MARKETS, COHATCH_LOCATIONS, COHATCH_MEETING_ROOMS } from "../../constant.js";

const MEAgreementForm = ({
  market, setMarket,
  location, setLocation,
  meetingLocation, setMeetingLocation,
  meetingDate, setMeetingDate,
  meetingStart, setMeetingStart,
  meetingEnd, setMeetingEnd,
  rate, setRate,
  addendum, setAddendum,
}) => {

  return (
    <>
      {/* Market Dropdown */}
      <div>
        <label className="block text-sm font-semibold text-gray-700">Select COhatch Market</label>
        <SelectInput
          options={COHATCH_MARKETS.market}
          value={COHATCH_MARKETS.market.find((item) => item.value === market)}
          setSelectedValue={(selected) => {
            setMarket(selected.value);
            setLocation(""); // Reset location when market changes
          }}
          placeholder="Choose a market"
          className="mt-1"
          required
        />
      </div>

      {/* Location Dropdown */}
      {market && (
        <div>
          <label className="block text-sm font-semibold text-gray-700">Select COhatch Location</label>
          <SelectInput
            options={COHATCH_LOCATIONS.cities[0][market]?.locations || []}
            value={location ? COHATCH_LOCATIONS.cities[0][market]?.locations.find((item) => item.value === location) : ""}
            setSelectedValue={(selected) => setLocation(selected.value)}
            placeholder="Choose a location"
            className="mt-1"
            required
          />
        </div>
      )}

      {/* Room Dropdown */}
      {location && (
        <div>
          <label className="block text-sm font-semibold text-gray-700">Select COhatch Meeting Space</label>
          <SelectInput
            options={COHATCH_MEETING_ROOMS[market]?.[location] || []}
            value={meetingLocation.room ? COHATCH_MEETING_ROOMS[market][location].find((item) => item.value === meetingLocation.room) : ""}
            onChange={(selected) => setMeetingLocation({ ...meetingLocation, room: selected.value })}
            placeholder="Choose a meeting space"
            className="mt-1"
            required
          />
        </div>
      )}

      {/* Meeting Date */}
      <div>
        <label htmlFor="meetingDate" className="block text-sm font-semibold text-gray-700">Meeting Date</label>
        <input
          type="date"
          onChange={(e) => setMeetingDate(e.target.value)}
          value={meetingDate}
          required
          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
        />
      </div>

      {/* Meeting Time */}
      <div>
        <label htmlFor="meetingTime" className="block text-sm font-semibold text-gray-700">Meeting Time</label>
        <div className="flex space-x-4">
          <input
            type="time"
            onChange={(e) => setMeetingStart(e.target.value)}
            value={meetingStart}
            required
            className="mt-1 block w-1/2 rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
          />
          <input
            type="time"
            onChange={(e) => setMeetingEnd(e.target.value)}
            value={meetingEnd}
            required
            className="mt-1 block w-1/2 rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
          />
        </div>
      </div>

      {/* Rate */}
      <div>
        <label htmlFor="rate" className="block text-sm font-semibold text-gray-700">
          Rate
        </label>
        <div className="relative">
          <span className="absolute left-3 top-2.5 text-gray-500">$</span>
          <input
            type="text"
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9\.]/g, "");
              const regex = /^[0-9]*\.?[0-9]*$/;
              if (regex.test(value)) {
                setRate(value);
              }
            }}
            value={rate}
            required
            className="mt-1 pl-6 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
          />
        </div>
      </div>

      {/* Addendum */}
      <div>
        <label htmlFor="addendum" className="block text-sm font-semibold text-gray-700">Addendum (Optional)</label>
        <input
          type="text"
          onChange={(e) => setAddendum(e.target.value)}
          value={addendum}
          className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
        />
      </div>
    </>
  );
};

export default MEAgreementForm;
