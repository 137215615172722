/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import notify from "../../services/toast";
import useUserContext from "../../hooks/useUserContext";
import createDocument from "./createDocument.js";
import { validateEmail, validateName } from "../../services/validation.js";
import Title from "../../components/title/Title.jsx";
import MEAgreementForm from "../../components/document_forms/MEAgreementForm.jsx";
import CCAuthorizationForm from "../../components/document_forms/CCAuthorizationForm.jsx";
import COhatchMembershipTermsForm from "../../components/document_forms/COhatchMembershipTermsForm.jsx"; // Import the new form

const DOCUMENT_TYPES = [
  { value: "MEAgreement", label: "Meeting & Event Agreement" },
  { value: "CCAuthorization", label: "Credit Card Authorization" },
  { value: "COhatchMembershipTerms", label: "COhatch Membership Agreement" } // Added this line
];

const SendDocumentPage = () => {
  const { userState } = useUserContext();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [submitting, setSubmitting] = useState(false);

  // Validation states
  const [validFirstName, setValidFirstName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [validEmail, setValidEmail] = useState(false);

  // ME Agreement fields
  const [market, setMarket] = useState("");
  const [location, setLocation] = useState("");
  const [meetingLocation, setMeetingLocation] = useState({ market: "", location: "", room: "" });
  const [meetingDate, setMeetingDate] = useState("");
  const [rate, setRate] = useState("");
  const [addendum, setAddendum] = useState("");
  const [meetingStart, setMeetingStart] = useState("");
  const [meetingEnd, setMeetingEnd] = useState("");

  // Credit Card Authorization fields
  const [companyName, setCompanyName] = useState("");
  const [primaryPhone, setPrimaryPhone] = useState("");

  // COhatch Membership Terms Fields
  const [addOns, setAddOns] = useState('');
  const [nonRefundableFee, setNonRefundableFee] = useState('');
  const [officeDeposit, setOfficeDeposit] = useState('');

  const [membershipStartDate, setMembershipStartDate] = useState("");
  const [membershipType, setMembershipType] = useState("");
  const [initialMembershipTerm, setInitialMembershipTerm] = useState("");
  const [assignedPrivateOffice, setAssignedPrivateOffice] = useState("");
  const [totalDueStart, setTotalDueStart] = useState("");
  const [totalDueMonthly, setTotalDueMonthly] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");


  useEffect(() => setValidFirstName(validateName(firstName)), [firstName]);
  useEffect(() => setValidLastName(validateName(lastName)), [lastName]);
  useEffect(() => setValidEmail(validateEmail(emailAddress)), [emailAddress]);

  function customTheme(theme) {
    return {
      ...theme,
      borderRadius: 5,
      colors: {
        ...theme.colors,
        primary25: "#ecf8ef",
        primary: "#56c271",
      },
    };
  }
  const style = {
    input: (provided, state) => ({
      ...provided,
      padding: 5,
    }),
  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setSubmitting(true);

      const documentPayload = {
        firstName,
        lastName,
        emailAddress,
        documentType,
        adminId: userState.id,
        meetingLocation: {
          market,
          location,
          room: meetingLocation.room
        },
        meetingDate,
        rate,
        addendum,
        meetingStart,
        meetingEnd,
        companyName,
        primaryPhone,
        membershipTerms: {
          membershipType,
          addOns,
          membershipStartDate, 
          initialMembershipTerm, 
          assignedPrivateOffice, 
          nonRefundableFee,
          officeDeposit,
          totalDueStart,
          totalDueMonthly,
          dateOfBirth,
        },
      };

      const documentResponse = await createDocument(userState.accessToken, documentPayload);

      if (documentResponse.status === "success") {
        notify("Document created successfully!", "success");
        setFirstName("");
        setLastName("");
        setEmailAddress("");
        setCompanyName("");
        setPrimaryPhone("");
        setDocumentType("");
        setMarket("");
        setLocation("");
        setMeetingDate("");
        setRate("");
        setAddendum("");
        setMeetingStart("");
        setMeetingEnd("");
        setMembershipStartDate("");
        setMembershipType("");
        setInitialMembershipTerm("");
        setAssignedPrivateOffice("");
        setStartUpFee("");
        setDeposit("");
        setTotalDueStart("");
        setTotalDueMonthly("");
        setMonthlyFee("");
        setDateOfBirth("");
      } else {
        notify("Document creation failed.", "error");
      }
      window.location.reload(); // Reload the page
    } catch (error) {
      notify("Something went wrong. Please try again later.", "error");
    } finally {
      setSubmitting(false);
    }
  };

  const isFormValid = () => {
    const baseValidation =
      validFirstName &&
      validLastName &&
      validEmail &&
      documentType;

    if (documentType === "MEAgreement") {
      return baseValidation &&
        meetingDate &&
        rate &&
        meetingStart &&
        meetingEnd &&
        meetingLocation.room;
    }

    if (documentType === "CCAuthorization") {
      return baseValidation &&
        companyName &&
        primaryPhone &&
        meetingDate &&
        rate &&
        meetingStart &&
        meetingEnd &&
        meetingLocation.room;
    }

    if (documentType === "COhatchMembershipTerms") {
      return (
        baseValidation &&
        market &&
        location &&
        companyName &&
        primaryPhone &&
        membershipStartDate &&
        membershipType &&
        totalDueStart &&
        totalDueMonthly &&
        nonRefundableFee &&
        dateOfBirth
      );
    }
    

    return false;
  };

 
  return (
    <div className="mx-auto max-w-5xl bg-white p-8 rounded-lg shadow-lg">
      <div className="mb-6 text-sm text-gray-700">
        <Title name="Send Documents for Signature" />
        <p className="text-base text-gray-600">
          Send documents for signature(s) by adding in the First and Last Name of the recipient as well as their email address. From there, select the type of document you’d like to send and fill in the rest of the form fields to generate the document for signature. An email will be sent to the signee after you hit submit. Once they sign the document, you will receive an email letting you know that the document is signed. A copy of the document will be saved here in COhatch+ Admin and the signee will be able to see their copy in COhatch+.
        </p>
      </div>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* First Name */}
        <div>
          <label htmlFor="firstname" className="block text-sm font-semibold text-gray-700">
            Recipient First Name
          </label>
          <input
            onChange={(event) => setFirstName(event.target.value)}
            value={firstName}
            id="firstname"
            name="firstname"
            type="text"
            required
            className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
          />
          {!validFirstName && firstName && (
            <p className="mt-1 text-xs text-red-500">Please enter a valid first name.</p>
          )}
        </div>

        {/* Last Name */}
        <div>
          <label htmlFor="lastname" className="block text-sm font-semibold text-gray-700">
            Recipient Last Name
          </label>
          <input
            onChange={(event) => setLastName(event.target.value)}
            value={lastName}
            id="lastname"
            name="lastname"
            type="text"
            required
            className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
          />
          {!validLastName && lastName && (
            <p className="mt-1 text-xs text-red-500">Please enter a valid last name.</p>
          )}
        </div>

        {/* Email Address */}
        <div>
          <label htmlFor="email" className="block text-sm font-semibold text-gray-700">
            Recipient Email Address
          </label>
          <input
            onChange={(event) => setEmailAddress(event.target.value)}
            value={emailAddress}
            id="email"
            name="email"
            type="email"
            required
            className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
          />
          {!validEmail && emailAddress && (
            <p className="mt-1 text-xs text-red-500">Please enter a valid email address.</p>
          )}
        </div>

        {/* Document Type */}
        <div>
          <label className="block text-sm font-semibold text-gray-700">Select Document Type</label>
          <Select
            options={DOCUMENT_TYPES}
            styles={style}
            theme={customTheme}
            value={DOCUMENT_TYPES.find((item) => item.value === documentType)}
            onChange={(selected) => setDocumentType(selected.value)}
            placeholder="Choose a document"
            className="mt-1"
            required
          />
        </div>

        {documentType === "MEAgreement" && (
          <MEAgreementForm
            market={market}
            setMarket={setMarket}
            location={location}
            setLocation={setLocation}
            meetingLocation={meetingLocation}
            setMeetingLocation={setMeetingLocation}
            meetingDate={meetingDate}
            setMeetingDate={setMeetingDate}
            meetingStart={meetingStart}
            setMeetingStart={setMeetingStart}
            meetingEnd={meetingEnd}
            setMeetingEnd={setMeetingEnd}
            rate={rate}
            setRate={setRate}
            addendum={addendum}
            setAddendum={setAddendum}
          />
        )}

        {documentType === "CCAuthorization" && (
          <CCAuthorizationForm
            companyName={companyName}
            setCompanyName={setCompanyName}
            primaryPhone={primaryPhone}
            setPrimaryPhone={setPrimaryPhone}
            market={market}
            setMarket={setMarket}
            location={location}
            setLocation={setLocation}
            meetingLocation={meetingLocation}
            setMeetingLocation={setMeetingLocation}
            meetingDate={meetingDate}
            setMeetingDate={setMeetingDate}
            meetingStart={meetingStart}
            setMeetingStart={setMeetingStart}
            meetingEnd={meetingEnd}
            setMeetingEnd={setMeetingEnd}
            rate={rate}
            setRate={setRate}
          />

        )}

        {/* Render appropriate form based on selected document type */}
        {documentType === "COhatchMembershipTerms" && (
          <COhatchMembershipTermsForm
            companyName={companyName}
            setCompanyName={setCompanyName}
            primaryPhone={primaryPhone}
            setPrimaryPhone={setPrimaryPhone}
            market={market}
            setMarket={setMarket}
            location={location}
            setLocation={setLocation}
            membershipType={membershipType}
            setMembershipType={setMembershipType}
            addOns={addOns}
            setAddOns={setAddOns}
            membershipStartDate={membershipStartDate}
            setMembershipStartDate={setMembershipStartDate}
            initialMembershipTerm={initialMembershipTerm}
            setInitialMembershipTerm={setInitialMembershipTerm}
            assignedPrivateOffice={assignedPrivateOffice}
            setAssignedPrivateOffice={setAssignedPrivateOffice}
            nonRefundableFee={nonRefundableFee}
            setNonRefundableFee={setNonRefundableFee}
            officeDeposit={officeDeposit}
            setOfficeDeposit={setOfficeDeposit}
            totalDueStart={totalDueStart}
            setTotalDueStart={setTotalDueStart}
            totalDueMonthly={totalDueMonthly}
            setTotalDueMonthly={setTotalDueMonthly}
            dateOfBirth={dateOfBirth}
            setDateOfBirth={setDateOfBirth}
          />
        )}

        <div className="text-right">
          <button
            type="submit"
            disabled={!isFormValid()}
            className={`bg-primary-400 text-white py-2 px-4 rounded-md ${!isFormValid() ? "opacity-50 cursor-not-allowed" : ""}`}
          >
            {submitting ? "Submitting..." : "Submit Document"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SendDocumentPage;
