/* eslint-disable */
import React, { useEffect, useState } from "react";
import useUserContext from "../../hooks/useUserContext";
import Spinner from "../../components/spinner/Spinner";
import notify from "../../services/toast";
import DefaultState from "../../components/default_state/DefaultState";
import getAllDocuments from "./getAllDocuments";
import { MAGIC_NUMBERS } from "../../constant";
import { Link } from "react-router-dom";
import { COHATCH_MARKETS, COHATCH_LOCATIONS, COHATCH_MEETING_ROOMS } from "../../constant";
import MEDocument from "../../components/cohatch_documents/MEDocument";
import CCAuthorization from "../../components/cohatch_documents/CCAuthorizationDoc";
import COhatchMembershipTermsDoc from "../../components/cohatch_documents/COhatchMembershipTermsDoc";

function AllDocuments() {
    const { userState } = useUserContext();
    const [documents, setDocuments] = useState([]);
    const [isDocumentsLoading, setIsDocumentsLoading] = useState(true);
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        remaining: 0,
        limit: 11,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [document, setSelectedDocument] = useState(null);

    // Handle opening modal with selected document data
    const handleViewClick = (selectedDoc) => {
        setSelectedDocument(selectedDoc);
        setIsModalOpen(true);
        console.log("Selected document:", selectedDoc);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedDocument(null);  // Ensure document state is reset
    };


    // Mapping for document types
    const DOCUMENT_TYPES_MAP = {
        MEAgreement: "Meeting & Event Agreement",
        HyggeCoworkingAgreement: "Hygge Coworking Agreement",
        CCAuthorization: "Credit Card Authorization",
        COhatchMembershipTerms: "COhatch Membership Agreement"
    };

    // Get Document Type Label
    const getDocumentTypeLabel = (documentType) => DOCUMENT_TYPES_MAP[documentType] || documentType;

    // Get Market Label
    const getMarketLabel = (marketValue) => {
        const market = COHATCH_MARKETS?.market?.find(item => item.value === marketValue);
        return market ? market.label : marketValue;
    };

    // Get Location Label
    const getLocationLabel = (marketValue, locationValue) => {
        // Loop through all cities in COHATCH_LOCATIONS
        for (const city of COHATCH_LOCATIONS.cities) {
            if (city[marketValue]) {  // Check if the city has this market
                const market = city[marketValue];
                const locations = market.locations || []; // Get locations for the market
                const location = locations.find(item => item.value === locationValue);

                if (location) {
                    return location.label;
                } else {
                    console.error(`Location not found for market: ${marketValue}, location: ${locationValue}`);
                    return locationValue; // Fallback to the original value
                }
            }
        }
        return marketValue; // Fallback if market is not found
    };


    // Get Meeting Room Label
    const getMeetingRoomLabel = (marketValue, locationValue, roomValue) => {
        const rooms = COHATCH_MEETING_ROOMS?.[marketValue]?.[locationValue] || []; // Ensure valid nesting
        const room = rooms.find(item => item.value === roomValue);
        return room ? room.label : roomValue;
    };

    useEffect(() => {
        fetchAllDocuments(userState.accessToken, pagination.page, pagination.limit);
    }, [userState.accessToken, pagination.page]);

    const fetchAllDocuments = async (accessToken, page = 1, limit = 11) => {
        try {
            setIsDocumentsLoading(true);

            const response = await getAllDocuments(accessToken, userState._id, page, limit);  // Pass pagination params

            if (response.status === "success") {
                setDocuments(response.data);
                setPagination({
                    ...pagination,
                    total: response.total,
                    remaining: response.remaining,
                    page: response.page,
                });
            } else {
                // Handle failure case
            }
        } catch (error) {
            console.log("Error while fetching documents", error);
        } finally {
            setIsDocumentsLoading(false);
        }
    };

    const handleNextPage = () => {
        if (pagination.page * pagination.limit < pagination.total) {
            setPagination(prev => ({
                ...prev,
                page: prev.page + 1
            }));
        }
    };

    const handlePrevPage = () => {
        if (pagination.page > 1) {
            setPagination(prev => ({
                ...prev,
                page: prev.page - 1
            }));
        }
    };

    return (
        <>
            <div className="bg-gray-50 min-h-screen py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-semibold text-gray-900 mb-8 text-center">
                        All Documents
                    </h2>

                    {isDocumentsLoading ? (
                        <div className="flex justify-center items-center">
                            <Spinner displayText="Loading documents..." />
                        </div>
                    ) : documents.length > 0 ? (
                        <div className="overflow-x-auto bg-white shadow sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Document Type
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Market & Location
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Sent By
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Received By
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Status
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {documents.map((document) => (
                                        <tr key={document._id} className="hover:bg-gray-50">
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {getDocumentTypeLabel(document.documentType)}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {document.meetingLocation && (
                                                    <div className="text-sm">
                                                        {/* Change labels and hide meeting room for COhatchMembershipTerms */}
                                                        {document.documentType === "COhatchMembershipTerms" ? (
                                                            <>
                                                                <div>
                                                                    <strong className="text-primary-400">Market:</strong> {getMarketLabel(document.meetingLocation.market)}
                                                                </div>
                                                                <div>
                                                                    <strong className="text-success-800">Location:</strong> {getLocationLabel(document.meetingLocation.market, document.meetingLocation.location)}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div>
                                                                    <strong className="text-primary-400">Market:</strong> {getMarketLabel(document.meetingLocation.market)}
                                                                </div>
                                                                <div>
                                                                    <strong className="text-success-800">Location:</strong> {getLocationLabel(document.meetingLocation.market, document.meetingLocation.location)}
                                                                </div>
                                                                <div>
                                                                    <strong className="text-success-50">Meeting Room:</strong> {getMeetingRoomLabel(document.meetingLocation.market, document.meetingLocation.location, document.meetingLocation.room)}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {document.adminEmail || "No Admin"}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {document.emailAddress || "No Email"}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {document?.signature ? "Signed" : "Sent"}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                <button
                                                    onClick={() => handleViewClick(document)}
                                                    className="text-primary-400 hover:text-success-900"
                                                >
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="flex justify-center">
                            <DefaultState
                                icon="file"
                                heading="No Documents Found"
                            />
                        </div>
                    )}

                    {pagination.total > pagination.limit && (
                        <div className="mt-8 flex justify-between">
                            {/* Hide Previous button if on the first page */}
                            {pagination.page > 1 && (
                                <button
                                    onClick={handlePrevPage}
                                    className="text-success-600 hover:text-success-900 font-medium"
                                >
                                    Previous
                                </button>
                            )}

                            {/* Hide Next button if on the last page */}
                            {pagination.page * pagination.limit < pagination.total && (
                                <button
                                    onClick={handleNextPage}
                                    className="text-success-600 hover:text-success-900 font-medium"
                                >
                                    Next
                                </button>
                            )}
                        </div>
                    )}

                    {isModalOpen && document && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto">
                            <div className="bg-white rounded-lg p-6 max-w-7xl w-full h-full max-h-screen overflow-y-scroll relative">
                                {/* Close button at top right */}
                                <button
                                    onClick={handleCloseModal}
                                    className="absolute top-4 right-4 bg-danger-500 text-white hover:bg-red-600 rounded-full p-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                                {/* Document content */}
                                {document.documentType === "MEAgreement" && <MEDocument document={document} />}
                                {document.documentType === "CCAuthorization" && <CCAuthorization document={document} />}
                                {document.documentType === "COhatchMembershipTerms" && <COhatchMembershipTermsDoc document={document} />}
                                {/* You can add more cases for other document types */}
                            </div>
                        </div>

                    )}
                </div>
            </div>
        </>
    );
}

export default AllDocuments;
