/* eslint-disable */
import React, { useRef } from "react";
import { COHATCH_MARKETS, COHATCH_LOCATIONS, COHATCH_MEETING_ROOMS } from "../../constant";
import html2pdf from "html2pdf.js";

function MEDocument({ document }) {
    const DOCUMENT_TYPES_MAP = {
        MEAgreement: "Meeting & Event Agreement",
        HyggeCoworkingAgreement: "Hygge Coworking Agreement",
        CCAuthorization: "Credit Card Authorization",
        COhatchMembershipTerms: "COhatch Membership Agreement"
    };

    const contentRef = useRef(null);

    // Helper function to get the display label
    const getDocumentTypeLabel = (documentType) => DOCUMENT_TYPES_MAP[documentType] || documentType;

    const getMarketLabel = (marketValue) => {
        const market = COHATCH_MARKETS?.market?.find(item => item.value === marketValue);
        return market ? market.label : marketValue;
    };

    const getLocationLabel = (marketValue, locationValue) => {
        // Loop through all cities in COHATCH_LOCATIONS
        for (const city of COHATCH_LOCATIONS.cities) {
            if (city[marketValue]) {  // Check if the city has this market
                const market = city[marketValue];
                const locations = market.locations || []; // Get locations for the market
                const location = locations.find(item => item.value === locationValue);

                if (location) {
                    return location.label;
                } else {
                    console.error(`Location not found for market: ${marketValue}, location: ${locationValue}`);
                    return locationValue; // Fallback to the original value
                }
            }
        }
        return marketValue; // Fallback if market is not found
    };

    const getMeetingRoomLabel = (marketValue, locationValue, roomValue) => {
        const rooms = COHATCH_MEETING_ROOMS?.[marketValue]?.[locationValue] || [];
        const room = rooms.find(item => item.value === roomValue);
        return room ? room.label : roomValue;
    };

    const formatTime = (timeString) => {
        const timeArray = timeString.split(":");
        let hours = parseInt(timeArray[0]);
        const minutes = timeArray[1];
        const period = hours >= 12 ? "PM" : "AM";

        // Convert 24-hour time to 12-hour format
        hours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

        // Return formatted time
        return `${hours}:${minutes} ${period}`;
    };

    const termsContent = [
        {
            header: ``,
            content: [
                `I acknowledge COhatch and its affiliates (collectively, “COhatch”) has granted me a revocable license to
          the applicable event / meeting space, whether hosted in-person or virtually. I acknowledge that I will
          follow all instructions and/guidelines issued by COhatch at all times during the duration of my event /
          meeting. COhatch reserves the right to revoke the license with or without cause. In particular, the license
          may be withdrawn to any person whose conduct is deemed to be disorderly, who uses abusive
          language, fails to comply with these terms, any physical or information security policies or any terms
          and/or rules imposed by the event premises or by COhatch, as applicable.`,
            ]
        },
        {
            header: `PAYMENT & CANCELLATION`,
            content: [
                `Full payment is due upon booking and can be made by ACH direct deposit or credit card. A 3.5% credit
          card processing fee is assessed for every credit card transaction. Reservations can be refunded or
          changed up to two weeks prior to the booking. If changes need to be made within 2 weeks of your
          booking, the invoice becomes non-refundable. A full credit will be applied to a future booking.`,

                `In order to book an after-hours social event - COhatch requires a credit card on file with the acceptance
          of said addendum. Failure to comply will result in cancellation.`
            ]
        },
        {
            header: "ACTS OF GOD",
            content: [
                `Sometimes things happen that are entirely out of our control, we get it. In the event of weather
          conditions that pose significant travel risk, COhatch will waive the two-day rescheduling notice, and
          allow for last minute scheduling changes to be made.`,

                `If for any reason COhatch is unable to fulfill its obligations, due to power outages, HVAC issues, or down
          WiFi, COhatch will assume full responsibility and deliver a full refund to the space renter.`
            ]
        },
        {
            header: `CONDUCT EXPECTATIONS INSIDE COHATCH`,
            content: [
                `Client agrees to comply with all applicable city, county, state, and federal laws and shall conduct no
          illegal act on the premises. This is always a drug free and non-smoking facility. Client may not serve
          alcohol to minors on the premises at any time. Client agrees, for everyone’s safety, to ensure alcoholic
          beverages are consumed in a responsible manner. COhatch reserves the right, in its exclusive
          discretion, to expel anyone who is intoxicated or under the influence of alcohol or drugs, or who shall in
          any manner do or participate in any act jeopardizing the rights, use permit, or insurability of COhatch or
          the safety of its staff, guests, or building contents.`
            ]
        },
        {
            header: "CONDUCT & SAFETY VIOLATIONS & FINES",
            content: [
                `Because our space is a shared space, we believe security and cleanliness of our locations is
          imperativeto our services for members, scholars, and non-members alike. We have expectations and
          fines associated with these expectations if they are not met as they are important to keep COhatch in
          working order for everyone who has access. Please read through the following and initial below to
          ensure that you are aware of our expectations and the fines that will be associated if they expectations
          are not adhered to during your meeting or event.`,
                "The following are the responsibilities of the parties involved:",
                [
                    `No doors shall be propped open at any time or a fine of $100/per door propped open will be
          assessed. This is to keep everyone safe and avoid any liability or damages that others may incur
          on your behalf.`,

                    `All doors shall be locked and secured at the end of your event. If a door is unlocked or not fully
          secured after your event, we will add a $250 fee to your invoice. This is to keep everyone safe
          and avoid any liability or damages that others may incur on your behalf.`,

                    `Failing to adhere to the agreed upon rental time frame (showing up too early or leaving too late)
          will result in a fine of $150 or more depending on how early or late you stay at our facilities. All of
          our bookings are made in advance and there may be others who have booked before or after
          your event, so please be sure to consider this when booking your time with us.`,

                    `Cleanliness is of the utmost importance to keeping our facility a top-notch meeting and event
          space. We do not provide cleaning services for events, so all clean-up is the responsibility of the
          person who booked the event with us. If we find that the space is overly dirty or damaged, we
          will assess and apply a $100-$500 cleaning fee depending on the condition the space is left in
          after your event.`,

                    `Damage to any COhatch property or facilities will be assessed based on the condition, cost of
          the items, or replacement items needed. This fine will be added to a final invoice for your
          meeting or event if incurred.`
                ]
            ]
        },
        {
            header: `ALCOHOL`,
            content: [
                `I provide my assurance that there will be no sale of alcohol during my event (though alcohol is allowed in
        COhatch premises, just not for the purposes of sale). I accept full responsibility for all alcohol
        consumption that occurs during my event and provide my assurance that all attendees will act
        responsibly on the COhatch premises and not disturb COhatch coworkers or office members. I accept
        full responsibility for any damages incurred to COhatch or COhatch affiliated partners because of any
        inebriated meeting / event attendees.`,

                `By signing below, the renter agrees to comply with all applicable city, county, state, and federal laws
        and shall conduct no illegal act on the premises. This is always a drug free and non-smoking facility.
        Renter may not serve alcohol to minors on the premises at any time. Renter agrees, for everyone’s
        safety, to ensure alcoholic beverages are consumed in a responsible manner. COhatch reserves the
        right, in its exclusive discretion, to expel anyone who is intoxicated or under the influence of alcohol or
        drugs, or who shall in any manner do or participate in any act jeopardizing the rights, use permit, or
        insurability of COhatch or the safety of its staff, guests, or building contents.`
            ]
        },

        {
            header: "FACILITIES",
            content: [
                ``,
                [
                    `All COhatch owned property must remain in the building.`,

                    `We ask that you replace any items that are moved in the space.`,

                    `Coffee machines and grinder are NOT available for use. Please don’t attempt to use the machine, as
          it can cause harm and severe burns due to the high temperatures of the water.`,

                    `COhatch does not allow any confetti or loose glitter of any kind.`,

                    `Command Strips are NOT permitted on the walls, we recommend painters tape only`,

                    `Please don't remove any of the furniture from the room that it's in. If furniture is rearranged within
          the room, all furniture must be put back to its original position.`,

                    `Client is responsible for the removal of trash at the end of the event.`,

                    `During the meeting or event walk-through, the designated trash area will be identified by the
          community manager.`
                ]
            ]
        },
        {
            header: "CLEAN UP PROCEDURES",
            content: [
                `For all after hours events:`,

                `Failure to complete items on the above list may result in a $200 cleaning fee, charged to the card
        on file.`,
                [
                    `Turn out all the lights`,
                    `Wipe all surfaces`,
                    `Load the dishwasher (if necessary)`,
                    `Lock the front door`,
                    `Turn off the music`,
                    `Take out the trash`
                ]
            ]
        },
        {
            header: "ITEMS NOT ALLOWED FOR MEETINGS OR EVENTS",
            content: [
                ``,
                [
                    `Glitter or confetti`,
                    `Fireworks`,
                    `Fog machines`,
                    `Crock pots or appliances (without approval from COhatch staff due to electrical compliance)`,
                    `No duct tape, double-sided hooks or command strips/hooks applied to COhatch property (walls,
          tables, etc)`,
                    `Enclosed or open flame of any kind`
                ]
            ]
        },
        {
            header: `LOAD-IN/LOAD-OUT AND STORAGE`,
            content: [
                `COhatch is not responsible for checking in or handling any items brought into the venue by rental
        companies. All external items must be checked in and signed for by the client or client’s representative.
        All excess material (such as bubble wrap, boxes, hangers, plastic, etc.) created by deliveries must be
        removed and disposed of by the rental company, client, or client’s representative and taken out with
        the rest of your event trash. Storage fees will be applied to any items left overnight or beyond normal
        load-in/load-out times. This also applies to items left post event for shipment out via courier. note: it is
        not the responsibility of COhatch to ensure that pick-ups are scheduled and executed.`,
            ]
        },
        {
            header: `LOST AND FOUND`,
            content: [
                `COhatch takes no responsibility for personal effects and possessions left on premises during or after
        any event. We do, however, maintain a lost and found and will hold recovered items up to 30 days.
        Every attempt will be made to return any recovered item to its rightful owner.`
            ]
        },
        {
            header: "LIABILITY",
            content: [
                `You will be held financially responsible for any property damage, injury, excessive mess, or lost or stolen
        COhatch property during your event/meeting. COhatch is fully equipped with indoor and outdoor
        cameras, so please note that all events are monitored. By signing this agreement, you agree to having
        your card charged for any damages/repairs caused by you or any of your guests. Report any damage
        that occurs during the event to the community manager. Failure to do so will result in a fine.`,
                "The agreement is valid for a period of 12 months.",
                "Termination of this agreement can occur under certain conditions."
            ]
        },
    ];
    const downloadPDF = () => {
        const element = contentRef.current;
        if (element) {
            // Set options for the pdf conversion
            const options = {
                margin: [1, 0, 1, 0], // Top, Right, Bottom, Left margins (clockwise)
                filename: "Meeting and Events Agreement.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 4 },
                jsPDF: { unit: "in", format: "a4", orientation: "portrait" }
            };

            // Use html2pdf to generate the PDF from the content
            html2pdf().from(element).set(options).save();
        } else {
            console.error("Element not found");
        }
    };



    return (
        <div className="space-y-6">
            <div id="authorization-content" className="bg-white shadow-lg rounded-lg w-full max-w-7xl p-6 mb-10" ref={contentRef}>
                {/* Content Inside the Modal */}
                <div className="items-center">
                    <div className="text-center">
                        <img
                            className="mx-auto mt-4 mb-4 h-auto w-36"
                            src="https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/static/images/COhatch%20Logo%20Horizontal%20w_tag%20green.png"
                            alt="COhatch Logo"
                        />
                    </div>

                    <h2 className="text-4xl font-semibold mb-6 text-center">
                        {getDocumentTypeLabel(document.documentType)}
                    </h2>

                    <div className="space-y-6 mb-6">
                        <div className="text-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2 text-success-800">
                            <div>
                                <h3 className="font-semibold text-lg">Meeting Location:</h3>
                                {document?.meetingLocation ? (
                                    <p>
                                        {getMarketLabel(document.meetingLocation.market)} -{" "}
                                        {getLocationLabel(document.meetingLocation.market, document.meetingLocation.location)} -{" "}
                                        {getMeetingRoomLabel(document.meetingLocation.market, document.meetingLocation.location, document.meetingLocation.room)}
                                    </p>
                                ) : (
                                    <p>Loading...</p>
                                )}
                            </div>

                            <div>
                                <h3 className="font-semibold text-lg">Meeting Date:</h3>
                                <p>{new Date(document.meetingDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}</p>
                            </div>

                            <div>
                                <h3 className="font-semibold text-lg">Meeting Start Time:</h3>
                                <p>{formatTime(document.meetingStart)}</p>
                            </div>

                            <div>
                                <h3 className="font-semibold text-lg">Meeting End Time:</h3>
                                <p>{formatTime(document.meetingEnd)}</p>
                            </div>

                            <div>
                                <h3 className="font-semibold text-lg">Rate:</h3>
                                <p>${document.rate}</p>
                            </div>
                        </div>

                        {termsContent.map((term, index) => (
                            <div key={index} className="bg-gray-50 p-4 rounded-md shadow-sm">
                                <h4 className="font-semibold text-lg">{term.header}</h4>
                                {term.content.map((item, idx) => {
                                    if (Array.isArray(item)) {
                                        return (
                                            <ul key={idx} className="list-disc pl-6">
                                                {item.map((point, pIdx) => (
                                                    <li key={pIdx} className="text-gray-600">{point}</li>
                                                ))}
                                            </ul>
                                        );
                                    } else {
                                        return <p key={idx} className="text-gray-600">{item}</p>;
                                    }
                                })}

                                {/* Display Initials if available */}
                                <div>
                                    {document?.initials && document.initials.length > 0 ? (
                                        <div>
                                            {document.initials
                                                .filter((initial) => {
                                                    return (index === 4 && initial.section === 'section5') || (index === 5 && initial.section === 'section6');
                                                })
                                                .map((initial, index) => (
                                                    <p key={index}>
                                                        <h3 className="font-semibold text-lg">Initials:</h3>
                                                        Your Initials: {initial.value}
                                                    </p>
                                                ))}
                                        </div>
                                    ) : (
                                        (index === 4 || index === 5) && (
                                            <div className="space-y-4 mt-4">
                                                <h4 className="font-semibold text-lg">Initials</h4>
                                                <p className="text-danger-500">Intials not yet submitted by the user:</p>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        ))}

                        <div className="bg-gray-50 p-4 rounded-md shadow-sm">
                            <h3 className="text-lg font-semibold text-left">ADDENDUM</h3>
                            <p>{document.addendum}</p>
                        </div>

                        <div className="flex flex-col sm:flex-row sm:gap-8 justify-left items-start mt-8">
                            {/* Signature Canvas Section */}
                            <div className="flex-1 sm:w-[55%]">
                                {document?.signature ? (
                                    <div className="sm:w-[40%] mt-4 sm:mt-0 text-center sm:text-left self-start bg-gray-50 rounded-lg shadow-md p-4">
                                        <h4 className="text-lg font-semibold text-left mb-4">SIGNATURE</h4>
                                        <div className="flex justify-center items-center p-4 bg-white border border-gray-200 rounded-lg">
                                            <img
                                                src={document.signature}
                                                alt="Signature Preview"
                                                className="max-w-full h-auto rounded-md"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="sm:mt-0 sm:text-left self-start p-4 bg-gray-50 rounded-lg shadow-md">
                                        <h3 className="text-xl font-semibold text-danger-500">
                                            SIGNATURE - Not yet submitted by user.
                                        </h3>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center">
                <button onClick={downloadPDF} className=" bg-primary-400 text-white py-2 px-4 rounded">
                    Download PDF
                </button>
            </div>
        </div>
    );
}

export default MEDocument;
