/* eslint-disable */
import React from 'react';
import SelectInput from '../select_input/SelectInput.jsx';
import { COHATCH_MARKETS, COHATCH_LOCATIONS, MEMBERSHIP_TYPES, } from "../../constant.js";


const COhatchMembershipTermsForm = ({
    companyName, setCompanyName,
    primaryPhone, setPrimaryPhone,
    dateOfBirth, setDateOfBirth,
    market, setMarket,
    location, setLocation,
    membershipType, setMembershipType,
    addOns, setAddOns,
    membershipStartDate, setMembershipStartDate,
    initialMembershipTerm, setInitialMembershipTerm,
    assignedPrivateOffice, setAssignedPrivateOffice,
    nonRefundableFee, setNonRefundableFee,
    officeDeposit, setOfficeDeposit,
    totalDueStart, setTotalDueStart,
    totalDueMonthly, setTotalDueMonthly,
}) => {

    return (
        <>
            {/* Company Name */}
            <div>
                <label htmlFor="companyName" className="block text-sm font-semibold text-gray-700">Company Name</label>
                <input
                    onChange={(event) => setCompanyName(event.target.value)}
                    value={companyName}
                    id="companyName"
                    name="companyName"
                    type="text"
                    className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                />
            </div>

            {/* Grid Layout for shorter fields */}
            <div className="grid grid-cols-2 gap-4">
                {/* Primary Phone Number */}
                <div>
                    <label htmlFor="primaryPhone" className="block text-sm font-semibold text-gray-700">Primary Phone Number</label>
                    <input
                        onChange={(event) => setPrimaryPhone(event.target.value)}
                        value={primaryPhone}
                        id="primaryPhone"
                        name="primaryPhone"
                        type="text"
                        required
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                    />
                </div>

                {/* Date of Birth */}
                <div>
                    <label htmlFor="dateOfBirth" className="block text-sm font-semibold text-gray-700">Date of Birth</label>
                    <input
                        type="date"
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        value={dateOfBirth}
                        required
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                    />
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
                {/* Membership Type */}
                <div>
                    <label className="block text-sm font-semibold text-gray-700">Membership Type</label>
                    <SelectInput
                        options={MEMBERSHIP_TYPES}
                        value={MEMBERSHIP_TYPES.find((item) => item.value === membershipType)}
                        setSelectedValue={(selected) => setMembershipType(selected.value)}
                        placeholder="Choose a membership type"
                        className="mt-1"
                        required
                    />
                </div>

                {/* Membership Start Date */}
                <div>
                    <label htmlFor="membershipStartDate" className="block text-sm font-semibold text-gray-700">Membership Start Date</label>
                    <input
                        type="date"
                        onChange={(e) => setMembershipStartDate(e.target.value)}
                        value={membershipStartDate}
                        required
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                    />
                </div>
            </div>


            {/* Home Market and Location (Grid) */}
            <div className="grid grid-cols-2 gap-4">
                {/* Home Market Dropdown */}
                <div>
                    <label className="block text-sm font-semibold text-gray-700">Select COhatch Market</label>
                    <SelectInput
                        options={COHATCH_MARKETS.market}
                        value={COHATCH_MARKETS.market.find((item) => item.value === market)}
                        setSelectedValue={(selected) => {
                            setMarket(selected.value);
                            setLocation(""); // Reset location when market changes
                        }}
                        placeholder="Choose a market"
                        className="mt-1"
                        required
                    />
                </div>

                {/* Home Location Dropdown */}
                {market && (
                    <div>
                        <label className="block text-sm font-semibold text-gray-700">Select COhatch Location</label>
                        <SelectInput
                            options={COHATCH_LOCATIONS.cities[0][market]?.locations || []}
                            value={location ? COHATCH_LOCATIONS.cities[0][market]?.locations.find((item) => item.value === location) : ""}
                            setSelectedValue={(selected) => setLocation(selected.value)}
                            placeholder="Choose a location"
                            className="mt-1"
                            required
                        />
                    </div>
                )}

            </div>

            <div className="grid grid-cols-2 gap-4">
                {/* Add-Ons */}
                <div>
                    <label htmlFor="addOns" className="block text-sm font-semibold text-gray-700">Add-Ons (optional)</label>
                    <input
                        onChange={(e) => setAddOns(e.target.value)}
                        value={addOns}
                        id="addOns"
                        name="addOns"
                        type="text"
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                    />
                </div>

                {/* Initial Membership Term */}
                <div>
                    <label htmlFor="initialMembershipTerm" className="block text-sm font-semibold text-gray-700">Initial Membership Term (optional)</label>
                    <input
                        onChange={(e) => setInitialMembershipTerm(e.target.value)}
                        value={initialMembershipTerm}
                        id="initialMembershipTerm"
                        name="initialMembershipTerm"
                        type="text"
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                    />
                </div>
            </div>

            {/* Remaining Fields */}
            <div className="grid grid-cols-2 gap-4">
                {/* Assigned Private Office/Desk */}
                <div>
                    <label htmlFor="assignedPrivateOffice" className="block text-sm font-semibold text-gray-700">Assigned Private Office/Desk at Home Location (Optional)</label>
                    <input
                        onChange={(e) => setAssignedPrivateOffice(e.target.value)}
                        value={assignedPrivateOffice}
                        id="assignedPrivateOffice"
                        name="assignedPrivateOffice"
                        type="text"
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                    />
                </div>

                {/* Non-Refundable Start-up Fee */}
                <div>
                    <label htmlFor="nonRefundableFee" className="block text-sm font-semibold text-gray-700">Non-Refundable Start-Up Fee</label>
                    <input
                        type="text"
                        onChange={(e) => setNonRefundableFee(e.target.value)}
                        value={nonRefundableFee}
                        required
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                    />
                </div>
            </div>

            {/* Deposit and Total Due at Start */}
            <div className="grid grid-cols-2 gap-4">
                {/* Office Deposit */}
                <div>
                    <label htmlFor="officeDeposit" className="block text-sm font-semibold text-gray-700">Private Office/Reserved Desk Deposit (Optional)</label>
                    <input
                        type="text"
                        onChange={(e) => setOfficeDeposit(e.target.value)}
                        value={officeDeposit}
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                    />
                </div>

                {/* Total Due at Start */}
                <div>
                    <label htmlFor="totalDueStart" className="block text-sm font-semibold text-gray-700">Total Due at Start of Membership</label>
                    <input
                        type="text"
                        onChange={(e) => setTotalDueStart(e.target.value)}
                        value={totalDueStart}
                        required
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                    />

                </div>
            </div>

            {/* Total Due Monthly */}
            <div>
                <label htmlFor="totalDueMonthly" className="block text-sm font-semibold text-gray-700">Total Due Each Subsequent Month ("Membership Fees")</label>
                <input
                    type="text"
                    onChange={(e) => setTotalDueMonthly(e.target.value)}
                    value={totalDueMonthly}
                    required
                    className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-primary-400 focus:border-primary-400"
                />
            </div>
        </>
    );
};

export default COhatchMembershipTermsForm;
