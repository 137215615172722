/* eslint-disable camelcase */
import {
	GiTowel,
	GiPillow,
	GiWashingMachine,
	GiSpiralBottle,
	GiCableStayedBridge,
	GiPingPongBat,
	GiPoolTableCorner,
	GiConsoleController,
	GiDesk,
	GiGasStove,
	GiToaster,
	GiCookingPot,
	GiTeapotLeaves,
	GiCctvCamera,
	GiFirstAidKit,
	GiGate,
	GiThermometerCold,
	GiThermometerHot,
	GiFireplace,
	GiWoodenChair,
	GiHomeGarage,
	GiDoorway,
	GiSofa,
	GiMountains,
	GiShower,
	GiPartyPopper,
	GiWoodenDoor,
} from "react-icons/gi";
import { TbBottle, TbHanger, TbApps, TbBooks, TbBeach } from "react-icons/tb";
import {
	FaBath,
	FaShower,
	FaTv,
	FaWifi,
	FaUtensils,
	FaFireExtinguisher,
	FaFan,
	FaBox,
	FaParking,
	FaUmbrellaBeach,
	FaSwimmingPool,
	FaHotTub,
	FaWater,
	FaWheelchair,
	FaChild,
} from "react-icons/fa";
import { BiBlanket, BiCloset } from "react-icons/bi";
import {
	MdIron,
	MdLocalLaundryService,
	MdSpeaker,
	MdMicrowave,
	MdOutlineMicrowave,
	MdOutlineCoffeeMaker,
	MdDining,
	MdCrib,
	MdElectricCar,
	MdFence,
	MdOutdoorGrill,
	MdBeachAccess,
	MdBalcony,
	MdWater,
	MdElevator,
	MdPets,
} from "react-icons/md";
import {
	CgSmartHomeRefrigerator,
	CgSmartHomeWashMachine,
} from "react-icons/cg";
import { RiAlarmWarningFill } from "react-icons/ri";
import { IoMdBonfire } from "react-icons/io";
import { GrLounge } from "react-icons/gr";

export const MAGIC_NUMBERS = {
	ZERO: 0,
	ONE: 1,
	TWO: 2,
	THREE: 3,
	FOUR: 4,
	FIVE: 5,
	REVIEW_LENGTH: 1000,
	MESSAGE_LENGTH: 2500,
	BIO_LENGTH: 4000,
	PHONE_LENGTH: 10,
	MIN_PROFILE_PHOTO_SIZE: 1, //1MB
	MAX_PROFILE_PHOTO_SIZE: 5, //MB
	BYTE_TO_MB: 1e6,
	SERVER_ERROR_CODES: [404, 500],
};

export const DEFAULT_PAGINATION_LIMIT = 11;

export const PLANS = {
	prod_La5g3PKJwMmwzK: "PREMIUM",
	prod_La5g7rXay2nqt3: "LOCAL DISCOUNT",
	prod_La5fuMlkuQWIl0: "BASIC",
	prod_La5fU3DTh1M2dr: "COHATCH",
};

export const ADMINTYPE = {
	MASTER_APPROVAL: "MASTER_APPROVAL",
	MARKET_LEADER: "MARKET_LEADER",
	SUPERADMIN: "SUPERADMIN",
	COMMUNITY_MANAGER: "COMMUNITY_MANAGER",
	TECHNICAL_SUPPORT: "TECHNICAL_SUPPORT",
	LEADERSHIP: "LEADERSHIP",
};

export const REMINDERS = [
	{ value: "subscription", label: "Subscription" },
	{ value: "email_verification", label: "Email Verification" },
	{ value: "create_listing", label: "Create Listing" },
	{ value: "profile", label: "Profile Information" },
	{ value: "basic_plan", label: "Basic Plan" },
];

export const REMINDERS_DUE_DATES = {
	subscription: 10,
	email_verification: 10,
	create_listing: 10,
	profile: 10,
	basic_plan: 30,
};

export const libraries = ["places"];

export const COHATCH_MARKETS = {
	market: [
		{ value: "atlanta", label: "Atlanta" },
		{ value: "charlotte", label: "Charlotte" },
		{ value: "cincinnati", label: "Cincinnati" },
		{ value: "cleveland", label: "Cleveland" },
		{ value: "columbus", label: "Columbus" },
		{ value: "indianapolis", label: "Indianapolis" },
		{ value: "pittsburgh", label: "Pittsburgh" },
		{ value: "springfield", label: "Springfield" },
		{ value: "tampa_bay", label: "Central Florida" },
	],
};

export const APP_STATUS = [
	{ value: "pending", label: "Pending" },
	{ value: "approved", label: "Approved" },
	{ value: "denied", label: "Denied" },
];
export const APP_LOCATIONS = {
	columbus: {
		locations: [
			{ value: "upper_arlington", label: "Upper Arlington" },
			{ value: "easton", label: "Easton" },
			{ value: "polaris", label: "Polaris" },
			{ value: "gateway", label: "The Gateway" },
			{ value: "delaware", label: "Delaware" },
			{ value: "dublin", label: "Dublin" },
			{ value: "westerville", label: "Westerville" },
			{ value: "worthington", label: "Worthington" },
			{ value: "bexley", label: "Bexley" },
		],
	},
	springfield: {
		locations: [{ value: "springfield", label: "Springfield" }],
	},
	cincinnati: {
		locations: [
			{ value: "covington", label: "Covington" },
			{ value: "hyde_park", label: "Hyde Park" },
			{ value: "mason", label: "Mason" },
			{ value: "milford", label: "Milford" },
			{ value: "kenwood", label: "Kenwood" },
			{ value: "over_the_rhine", label: "Over The Rhine (OTR)" },
		],
	},
	cleveland: {
		locations: [
			{ value: "beachwood", label: "Beachwood" },
			{ value: "ohio_city", label: "Ohio City" },
		],
	},

	indianapolis: {
		locations: [
			{ value: "broad_ripple", label: "Broad Ripple" },
			{ value: "carmel", label: "Carmel" },
			{ value: "circle_centre", label: "Circle Centre" },
			{ value: "noblesville", label: "Noblesville" },
			{ value: "zionsville", label: "Zionsville" },
			{ value: "polk_stables", label: "Polk Stables" },
		],
	},

	pittsburgh: {
		locations: [
			{ value: "waterfront", label: "Waterfront" },
			{ value: "southside_works", label: "Southside Works" },
			{ value: "shadyside", label: "Shadyside" },
		],
	},
	tampa_bay: {
		locations: [
			{ value: "lakeland", label: "Lakeland" },
			{ value: "st_petersburg", label: "St. Petersburg" },
			{ value: "west tampa", label: "West Tampa" },
			{ value: "tarpon springs", label: "Tarpon Springs" },
		],
	},
	charlotte: {
		locations: [
			{ value: "3rd_ward", label: "3RD Ward" },
			{ value: "belmont", label: "Belmont" },
			{ value: "camp_greene", label: "Camp Greene" },
			{ value: "camp_north_end", label: "Camp North End" },
			{ value: "wesley_heights", label: "Wesley Heights" },
		],
	},
	atlanta: {
		locations: [{ value: "duluth", label: "Duluth" }],
	},
};

export const COHATCH_LOCATIONS = {
	cities: [
		{
			columbus: {
				locations: [
					{ value: "bexley", label: "Bexley" },
					{ value: "delaware", label: "Delaware" },
					{ value: "dublin", label: "Dublin" },
					{ value: "easton", label: "Easton" },
					{ value: "gateway", label: "The Gateway" },
					{ value: "polaris", label: "Polaris" },
					{ value: "upper_arlington", label: "Upper Arlington" },
					{ value: "westerville", label: "Westerville" },
					{ value: "worthington_hardware", label: "Worthington - The Hardware Store" },
					{ value: "worthington_library", label: "Worthington - The Library & The Madery" },
				],
			},
			cincinnati: {
				locations: [
					{ value: "covington", label: "Covington" },
					{ value: "hyde_park", label: "Hyde Park" },
					{ value: "kenwood", label: "Kenwood" },
					{ value: "mason", label: "Mason" },
					{ value: "milford", label: "Milford" },
					{ value: "over_the_rhine", label: "Over The Rhine (OTR)" },
					
				],
			},

			cleveland: {
				locations: [
					{ value: "beachwood", label: "Beachwood" },
					{ value: "ohio_city", label: "Ohio City" },
				],
			},

			indianapolis: {
				locations: [
					{ value: "broad_ripple", label: "Broad Ripple" },
					{ value: "carmel", label: "Carmel" },
					{ value: "circle_centre", label: "Circle Centre" },
					{ value: "noblesville", label: "Noblesville" },
					{ value: "polk_stables", label: "Polk Stables" },
					{ value: "zionsville", label: "Zionsville" },
				],
			},

			pittsburgh: {
				locations: [
					{ value: "waterfront", label: "Waterfront" },
					{ value: "shadyside", label: "Shadyside" },
					{ value: "southside_works", label: "Southside works" },
				],
			},

			tampa_bay: {
				locations: [
					{ value: "lakeland", label: "Lakeland" },
					{ value: "st_petersburg", label: "St. Petersburg" },
					{ value: "tarpon_springs", label: "Tarpon Springs" },
					{ value: "west_tampa", label: "West Tampa" },
				],
			},

			charlotte: {
				locations: [
					{ value: "3rd_ward", label: "3RD Ward" },
					{ value: "belmont", label: "Belmont" },
					{ value: "camp_greene", label: "Camp Green" },
					{ value: "camp_north_end", label: "Camp North End" },
					{ value: "wesley_heights", label: "Wesley Heights" },
				],
			},
			atlanta: {
				locations: [
					{ value: "duluth", label: "Duluth" },
					{ value: "lawrenceville", label: "Lawrenceville" },
				],
			},
			springfield: {
				locations: [
					{ value: "springfield", label: "Springfield" },
				],
			},
		},
	],
};

export const COHATCH_MEETING_ROOMS = {
	columbus: {
		bexley: [
			{ value: "arbor_room", label: "Arbor Room" },
			{ value: "capital_room", label: "Capital Room" },
			{ value: "first_ring_room", label: "First Ring Room" },
			{ value: "glow_room", label: "Glow Room" },
			{ value: "heritage_room", label: "Heritage Room" },
			{ value: "unicorn_room", label: "Unicorn Room" },
			{ value: "perch", label: "The Perch" },
			{ value: "reception_area", label: "Reception Area" },
		],
		delaware: [
			{ value: "black_and_white_room", label: "Black and White Room" },
			{ value: "comic_room", label: "Comic Room" },
			{ value: "hidden_room", label: "Hidden Room" },
			{ value: "press_room", label: "Press Room" },
			{ value: "stack_room", label: "Stack Room" },
			{ value: "top_spin_room", label: "The Top Spin Room" },
			{ value: "delaware_theater", label: "Delaware Theater" },
			{ value: "hall_of_mirrors", label: "Hall Of Mirrors (Patio Included-Book Black & White)" },
		],
		dublin: [
			{ value: "bridge_room", label: "Bridge Room" },
			{ value: "dublin_board_room", label: "Dublin Board Room" },
			{ value: "foliage_room", label: "Foliage Room" },
			{ value: "riverstone_room", label: "Riverstone Room" },
			{ value: "round_room", label: "Round Room" },
			{ value: "arena_at_mesh_fitness", label: "The Arena at Mesh Fitness" },
			{ value: "balance_room_at_mesh_fitness", label: "The Balance Room at Mesh Fitness" },
			{ value: "black_and_tan_room", label: "Black and Tan Room" },
			{ value: "commons", label: "Commons" },
		],
		easton: [
			{ value: "easton_podcast", label: "Easton Podcast" },
			{ value: "room_50", label: "Room 50" },
			{ value: "room_60", label: "Room 60" },
			{ value: "room_70", label: "Room 70" },
			{ value: "room_80", label: "Room 80" },
			{ value: "room_90", label: "Room 90" },
			{ value: "gathering_place", label: "The Gathering Place" },
			{ value: "rock_wall", label: "The Rock Wall" },
		],
		gateway: [
			{ value: "audiofile_room", label: "Audiofile Room" },
			{ value: "back_lab", label: "Back Lab" },
			{ value: "firewall_room", label: "Firewall Room" },
			{ value: "horseshoe_room", label: "Horseshoe Room" },
			{ value: "mirror_room", label: "Mirror Room" },
			{ value: "ram_room", label: "Ram Room" },
			{ value: "design_lab", label: "The Design Lab" },
		],
		polaris: [
			{ value: "ashland_suite", label: "Ashland Suite" },
			{ value: "confluence_room", label: "Confluence Room" },
			{ value: "fingerprint_room", label: "Fingerprint Room" },
			{ value: "honeycomb_room", label: "Honeycomb Room" },
			{ value: "middle_west", label: "Middle West" },
			{ value: "north_high", label: "North High" },
			{ value: "orbit_room", label: "Orbit Room" },
			{ value: "sand_room", label: "Sand Room" },
			{ value: "seeing_plaid", label: "Seeing Plaid" },
			{ value: "hub_table_16", label: "The Hub (Table for 16)" },
			{ value: "hub_lounge", label: "The Hub (The Lounge)" },
			{ value: "pavilion", label: "The Pavilion" },
			{ value: "underground_room", label: "Underground Room" },
			{ value: "mobi", label: "Mobi" },
			{ value: "polaris_east", label: "Polaris East" },
			{ value: "pub_pavilion", label: "The Pub - Book Pavillion" },
			{ value: "meridian", label: "The Meridian" },
			{ value: "west_wing", label: "West Wing" },
		],
		upper_arlington: [
			{ value: "45_room", label: "45 Room" },
			{ value: "armadillo_room", label: "Armadillo Room" },
			{ value: "black_and_gold_room", label: "Black & Gold Room" },
			{ value: "kodiak_room", label: "Kodiak Room" },
			{ value: "moss_room", label: "Moss Room" },
			{ value: "lookout", label: "The Lookout" },
		],
		westerville: [
			{ value: "dry_room", label: "Dry Room" },
			{ value: "hoover_room", label: "Hoover Room" },
			{ value: "ombre_room", label: "Ombre Room" },
			{ value: "tablemen_room", label: "Tablemen Room" },
			{ value: "cellar", label: "The Cellar" },
			{ value: "x_room", label: "X Room" },
			{ value: "garden_room", label: "Garden Room" },
			{ value: "armory_alley", label: "Armory Alley" },
		],
		worthington_hardware: [
			{ value: "alpha_room", label: "Alpha Room" },
			{ value: "ampersand_room", label: "Ampersand Room" },
			{ value: "launchpad", label: "Launchpad" },
			{ value: "bounce_house", label: "Bounce House" },
			{ value: "community_hall_kitchen", label: "Community Hall & Kitchen" },
		],
		worthington_library: [
			{ value: "back_room", label: "The Back Room" },
			{ value: "little_room", label: "The Little Room" },
			{ value: "open_room", label: "The Open Room" },
			{ value: "tall_room", label: "The Tall Room" },
			{ value: "kilbourne_room", label: "Kilbourne Room (+Patio+Kitchen+Game Room)" },
		],
	},
	cincinnati: {
		covington: [
			{ value: "barrell_room", label: "Barrell Room" },
			{ value: "blue_room", label: "Blue Room" },
			{ value: "green_line", label: "Green Line" },
			{ value: "map_room", label: "Map Room" },
			{ value: "chimes_room", label: "Chimes Room" },
			{ value: "the_summit", label: "The Summit - 30 Seats Event Space" },
		],
		hyde_park: [
			{ value: "board_room", label: "Board Room" },
			{ value: "pine_room", label: "Pine Room" },
			{ value: "poker_room", label: "Poker Room" },
			{ value: "radio_room", label: "Radio Room" },
			{ value: "launch_pad", label: "Launch Pad" },
			{ value: "brick", label: "The Brick" },
		],
		kenwood: [
			{ value: "chili_bowl", label: "The Chili Bowl" },
			{ value: "dipped_room", label: "The Dipped Room" },
			{ value: "paper_mill", label: "The Paper Mill" },
			{ value: "banana_phone", label: "Banana Phone" },
			{ value: "kitchenette_space", label: "Kitchenette Space" },
			{ value: "outdoor_patio", label: "Outdoor Patio" },
			{ value: "plaza_98", label: "Plaza 98" },
			{ value: "sports_sim", label: "Sports Sim" },
			{ value: "stardust", label: "The Stardust" },
		],
		mason: [
			{ value: "far_room", label: "Far Room" },
			{ value: "jailbird_room", label: "Jailbird Room" },
			{ value: "podcast_mason", label: "Podcast Mason" },
			{ value: "reveal_room", label: "Reveal Room" },
			{ value: "atrium", label: "Atrium" },
			{ value: "foyer_patio", label: "Foyer + Patio" },
			{ value: "mason_theatre", label: "Mason Theatre" },
			{ value: "reading_room", label: "Reading Room" },
			{ value: "behind_the_bookshelf", label: "Behind The Bookshelf" },
		],
		milford: [
			{ value: "moon_room", label: "Moon Room" },
			{ value: "radial_room", label: "Radial Room" },
			{ value: "upscale_room", label: "Upscale Room" },
			{ value: "blue_bike", label: "Blue Bike" },
			{ value: "deck", label: "Deck" },
			{ value: "gracie", label: "Gracie" },
			{ value: "red_bike", label: "Red Bike" },
			{ value: "silver_bike", label: "Silver Bike" },
			{ value: "barracuda", label: "The Barracuda" },
			{ value: "white_bike", label: "White Bike" },
		],
		over_the_rhine: [
			{ value: "cornhole_room", label: "Cornhole Room" },
			{ value: "mechanical_room", label: "Mechanical Room" },
			{ value: "opportunity_room", label: "Opportunity Room" },
			{ value: "treehouse", label: "The Treehouse" },
			{ value: "avalon", label: "Avalon" },
			{ value: "carpe_diem", label: "Carpe Diem" },
			{ value: "northern_liberties", label: "Northern Liberties" },
			{ value: "seven_hills_room", label: "Seven Hills Room" },
			{ value: "rooftop", label: "The Rooftop" },
		],
	},
	cleveland: {
		beachwood: [
			{ value: "room_with_a_view", label: "A Room With A View" },
			{ value: "clerical_room", label: "Clerical Room" },
			{ value: "crane_room", label: "Crane Room" },
			{ value: "expedition_room", label: "Expedition Room" },
			{ value: "open_room", label: "Open Room" },
			{ value: "portrait_room", label: "Portrait Room" },
			{ value: "rocket_room", label: "Rocket Room" },
			{ value: "north_high_brewing", label: "North High Brewing" },
			{ value: "open_space_cost", label: "Open Space Cost" },
			{ value: "hive", label: "The Hive" },
			{ value: "total_floor_buyout", label: "Total Floor Buyout" },
			{ value: "watkins_green", label: "The Watkin's Green" },
		],
		ohio_city: [
			{ value: "heisman_room", label: "Heisman Room" },
			{ value: "hinge_room", label: "Hinge Room" },
			{ value: "ink_room", label: "Ink Room" },
			{ value: "podcast_ohio_city", label: "Podcast Ohio City" },
			{ value: "ringside_room", label: "Ringside Room" },
			{ value: "front_lounge", label: "Front Lounge" },
			{ value: "moon_room", label: "Moon Room" },
		],
	},
	indianapolis: {
		broad_ripple: [
			{ value: "big_room", label: "Big Room" },
			{ value: "blueprint_room", label: "Blueprint Room" },
			{ value: "bison_room", label: "Bison Room" },
			{ value: "downstairs_kitchen_space", label: "Downstairs Kitchen Space" },
			{ value: "mulligan_room", label: "Mulligan Room" },
			{ value: "oxbow_room", label: "Oxbow Room" },
			{ value: "patio_main_kitchen_area", label: "Patio and Main Kitchen Area" },
			{ value: "pin_up_room", label: "Pin Up Room" },
			{ value: "podcast_broad_ripple", label: "Podcast Broad Ripple" },
			{ value: "stained_glass_room", label: "Stained Glass Room" },
			{ value: "two_24", label: "2-24" },
		],
		carmel: [
			{ value: "boombox_room", label: "Boombox Room"},
			{ value: "garden_room", label: "Garden Room" },
			{ value: "gearbox_room", label: "Gearbox Room" },
			{ value: "koen_room", label: "Koen Room" },
			{ value: "kitchen_reception_area", label: "Kitchen/Reception Area" },
			{ value: "lantern_room", label: "Lantern Room" },
			{ value: "noir_room", label: "Noir Room" },
			{ value: "station_38", label: "Station 38" },
		],
		noblesville: [
			{ value: "inside_outside", label: "Inside Outside" },
			{ value: "podcast_room", label: "Podcast Room" },
			{ value: "stripe_room", label: "Stripe Room" },
			{ value: "truss_room", label: "Truss Room" },
		],
		circle_centre: [
			{ value: "crown_jewel", label: "The Crown Jewel" },
			{ value: "growth_room", label: "Growth Room" },
			{ value: "speedway_room", label: "Speedway Room" },
			{ value: "storefront_room", label: "Storefront Room" },
			{ value: "tavern_garage", label: "The Tavern & The Garage" },
		],
		zionsville: [
			{ value: "gravity_room", label: "Gravity Room" },
			{ value: "hipster_cast", label: "Hipster Cast" },
			{ value: "kings_room", label: "King's Room" },
			{ value: "main_coworking_space_kitchen", label: "Main Coworking Space and Kitchen" },
			{ value: "mixtape_room", label: "Mixtape Room" },
			{ value: "word_room", label: "Word Room" },
			{ value: "upper_bar_and_patio", label: "Upper Bar & Patio" },
		],
		polk_stables: [
			{ value: "1872_room", label: "1872 Room" },
			{ value: "bee_line_room", label: "Bee Line Room" },
			{ value: "cannery_balcony", label: "The Cannery & Balcony" },
			{ value: "chess_room", label: "Chess Room" },
			{ value: "dairy_room", label: "The Dairy Room" },
			{ value: "green_room_patio", label: "The Green Room & Patio" },
			{ value: "montgomery_room", label: "Montgomery Room" },
			{ value: "polklore", label: "Polklore" },
			{ value: "sound_cave", label: "Sound Cave" },
			{ value: "sunshine_studios", label: "Sunshine Studios" },
		],
	},
	pittsburgh: {
		waterfront: [
		  { value: "412_room", label: "412 Room" },
		  { value: "atrium_open_coworking_space", label: "Atrium/Open Coworking Space" },
		  { value: "furnace_room", label: "Furnace Room" },
		  { value: "green_room", label: "Green Room" },
		  { value: "incline_loft", label: "Incline Loft" },
		  { value: "mill_room", label: "Mill Room" },
		  { value: "smithfield_theater", label: "Smithfield Theater" },
		  { value: "westwood_room", label: "Westwood Room" },
		],
		shadyside: [
		  { value: "basement_event_space", label: "Basement Event Space" },
		  { value: "coworking_first_floor", label: "Coworking First Floor" },
		  { value: "fahlman_room", label: "Fahlman Room" },
		  { value: "roslyn_room", label: "Roslyn Room" },
		  { value: "rider_penthouse", label: "Rider Penthouse" },
		  { value: "spoonwood_room", label: "Spoonwood Room" },
		  { value: "spruce_room", label: "Spruce Room" },
		  { value: "ward_hall", label: "Ward Hall" },
		],
		southside_works: [
		  { value: "art_soul", label: "Art & Soul" },
		  { value: "beware_the_ouga", label: "Beware the Ogua" },
		  { value: "carson_room", label: "Carson Room" },
		  { value: "firefly_room", label: "Firefly Room" },
		  { value: "glass_room", label: "Glass Room" },
		  { value: "morning_glory", label: "Morning Glory" },
		  { value: "cm_desk_area", label: "CM Desk Area" },
		  { value: "pixel", label: "The Pixel" },
		],
	  },
	  tampa_bay: {
		lakeland: [
		  { value: "longwing_room", label: "Longwing Room" },
		  { value: "polk_room", label: "Polk Room" },
		  { value: "skipper_room", label: "Skipper Room" },
		  { value: "bridge", label: "The Bridge" },
		  { value: "nest", label: "The Nest" },
		  { value: "cafe", label: "The Cafe" },
		  { value: "everglade", label: "The Everglade" },
		],
		st_petersburg: [
		  { value: "768_room", label: "768 Room" },
		  { value: "ballroom", label: "Ballroom" },
		  { value: "manhattan_room", label: "Manhattan Room" },
		  { value: "flora_room", label: "Flora Room" },
		  { value: "lager_lounge", label: "Lager Lounge" },
		],
		tarpon_springs: [
			{ value: "beam_room", label: "Beam Room" },
			{ value: "aquarium", label: "The Aquarium" },
			{ value: "muse_room", label: "Muse Room" },
			{ value: "yoyo_room", label: "YoYo Room" },
			{ value: "breezeway", label: "The Breezeway" },
		  ],
		west_tampa: [
			{ value: "big_guava", label: "Big Guava" },
			{ value: "container_stage", label: "Container Stage" },
			{ value: "lunchroom", label: " Lunchroom" },
			{ value: "the_humidor", label: "The Humidor" },
			{ value: "the_streetcar", label: "The Streetcar" },
			{ value: "the_wilds", label: "The Wilds" },
		  ],
	  },

	  charlotte: {
		"3rd_ward": [
		  { value: "treehouse", label: "Treehouse" },
		  { value: "huddle_room", label: "Huddle Room" },
		],
		belmont: [
		  { value: "mill_village", label: "Mill Village" },
		  { value: "wiggle_room", label: "Wiggle Room" },
		  { value: "eastend", label: "Eastend" },
		  { value: "parkwood", label: "Parkwood" },
		  { value: "cordelia", label: "Cordelia" },
		  { value: "sunnyside_spot", label: "Sunnyside Spot" },
		],
		camp_green: [
		  { value: "nice_to_meet_you", label: "Nice To Meet You" },
		  { value: "more_meetings_more_problems", label: "More Meetings, More Problems" },
		],
		camp_north_end: [
		  { value: "blue_classroom", label: "Blue Classroom" },
		  { value: "huddle_room", label: "Huddle Room" },
		],
		wesley_heights: [
		  { value: "fancy_meeting_you_here", label: "Fancy Meeting You Here" },
		  { value: "please_to_meet_you", label: "Please To Meet You" },
		  { value: "podcast_studio", label: "Podcast Studio" },
		  { value: "meeting_of_the_minds", label: "Meeting Of The Minds" },
		  { value: "pencil_me_in", label: "Pencil Me In" },
		  { value: "lets_circle_up", label: "Let's Circle Up" },
		],
	  },
	  atlanta: {
		duluth: [
		  { value: "divide_room", label: "Divide Room" },
		  { value: "festival_room", label: "Festival Room" },
		  { value: "suds_room", label: "Suds Room" },
		  { value: "workshop_room", label: "Workshop Room" },
		  { value: "outdoor_patio", label: "Outdoor Patio" },
		  { value: "annex_private_patio", label: "The Annex + Private Patio" },
		],
	  },

	  springfield: {
		springfield: [
			{ value: "corner_room", label: "Corner Room" },
			{ value: "heritage_room", label: "Heritage Room" },
			{ value: "rise_room", label: "Rise Room" },
			{ value: "the_basement", label: "The Basement" },
			{ value: "the_exchange", label: "The exchange" },
			{ value: "the_mezzanine", label: "The Mezzanine" },
		],
	  },
};

export const MEMBERSHIP_TYPES = [
    // Coworking Plans
    { value: "1_day_a_week_coworking_plan", label: "1 Day a Week Coworking Plan" },
    { value: "1_location_part_time_springfield_2025", label: "1 Location Part-Time Springfield 2025" },
    { value: "1_location_starter", label: "1 Location Starter" },
    { value: "1_location_unlimited_coworking", label: "1 Location Unlimited Coworking" },
    { value: "1_location_unlimited_springfield_2025", label: "1 Location Unlimited Springfield 2025" },
    { value: "1_location_unlimited_springfield_promo", label: "1 Location Unlimited Springfield Promo" },
    { value: "2022_part_time_coworking", label: "2022 Part Time Coworking" },
    { value: "2022_unlimited_coworking", label: "2022 Unlimited Coworking" },
    { value: "part_time_coworking", label: "Part-Time Coworking" },
    { value: "unlimited_coworking", label: "Unlimited Coworking" },
    { value: "unlimited_plus", label: "Unlimited+" },
    { value: "young_professional_coworking", label: "Young Professional Coworking" },
    
    // Boost Scholarship Memberships
    { value: "boost_scholarship_membership_year_1", label: "Boost Scholarship Membership - Year 1" },
    { value: "boost_scholarship_membership_year_2", label: "Boost Scholarship Membership - Year 2" },
    { value: "boost_scholarship_membership_year_3", label: "Boost Scholarship Membership - Year 3" },
    
    // Podcaster Memberships
    { value: "part_time_podcaster", label: "Part-Time Podcaster" },
    { value: "professional_podcaster", label: "Professional Podcaster" },
    { value: "starter_podcaster", label: "Starter Podcaster" },
    
    // Day Passes
    { value: "day_pass", label: "Day Pass" },
    { value: "bulk_day_pass", label: "Bulk Day Pass" },
    { value: "business_day_pass", label: "Business Day Pass" },
    { value: "desana_day_pass", label: "Desana Day Pass" },
    { value: "gable_day_pass", label: "Gable Day Pass" },
    { value: "liquidspace_day_pass", label: "Liquidspace Day Pass" },
    { value: "upflex_day_pass", label: "Upflex Day Pass" },
    { value: "deskpass_day_pass", label: "Deskpass Day Pass" },
    
    // Corporate Coworking
    { value: "corporate_coworking_149_per_member", label: "Corporate Coworking ($149 Per Member)" },
    { value: "corporate_coworking_225_per_member", label: "Corporate Coworking ($225 Per Member)" },
    { value: "part_time_corporate_coworking_160_per_member", label: "Part Time Corporate Coworking ($160 Per Member)" },

    // Membership Passes
    { value: "coworking_summer_pass", label: "Coworking Summer Pass" },
    { value: "give_scholarship_membership", label: "Give Scholarship Membership" },
    { value: "junior_part_time_coworking", label: "JUNIOR Part Time Coworking" },
    { value: "meeting_room_pass", label: "Meeting Room Pass" },
    { value: "meeting_room_pass_10_hours", label: "Meeting Room Pass - 10 Hours" },
    { value: "springfield_meeting_pass", label: "Springfield Meeting Pass" },
    
    // Office Space
    { value: "dedicated_desk", label: "Dedicated Desk" },
    { value: "member_flex_office", label: "Member Flex Office" },
    { value: "1_person_private_office", label: "1 Person Private Office" },
    { value: "2_person_private_office", label: "2 Person Private Office" },
    { value: "3_person_private_office", label: "3 Person Private Office" },
    { value: "4_person_private_office", label: "4 Person Private Office" },
    { value: "5_person_private_office", label: "5 Person Private Office" },
    { value: "8_person_private_office", label: "8 Person Private Office" },
    { value: "non_member_flex_office", label: "Non Member Flex Office" },

    // Miscellaneous
    { value: "dog_pass", label: "Dog Pass" },
    { value: "it_rack_space_rental", label: "IT Rack Space Rental" },
    
    // Kitchen Plans
	{ value: "8_hour_kitchen_plan", label: "8 Hour Kitchen Plan" },
    { value: "20_hour_kitchen_plan", label: "20 Hour Kitchen Plan" },
    { value: "40_hour_kitchen_plan", label: "40 Hour Kitchen Plan" },
    { value: "60_hour_kitchen_plan", label: "60 Hour Kitchen Plan" },
    { value: "80_hour_kitchen_plan", label: "80 Hour Kitchen Plan" },

    // Added Items
    { value: "mail_service", label: "Mail Service" }, // Missing
    { value: "starter_coworking", label: "Starter Coworking" } // Missing
];


export const LOCATIONS = [
	{ value: "3rd_ward", label: "3RD Ward" },
	{ value: "beachwood", label: "Beachwood" },
	{ value: "belmont", label: "Belmont" },
	{ value: "bexley", label: "Bexley" },
	{ value: "broad ripple", label: "Broad Ripple" },
	{ value: "camp_north_end", label: "Camp North End" },
	{ value: "carmel", label: "Carmel" },
	{ value: "covington", label: "Covington" },
	{ value: "delaware", label: "Delaware" },
	{ value: "downtown-indy", label: "Downtown Indy" },
	{ value: "dublin", label: "Dublin" },
	{ value: "duluth", label: "Duluth" },
	{ value: "easton", label: "Easton" },
	{ value: "gateway", label: "Gateway" },
	{ value: "hyde_park", label: "Hyde Park" },
	{ value: "kenwood", label: "Kenwood" },
	{ value: "lakeland", label: "Lakeland" },
	{ value: "mason", label: "Mason" },
	{ value: "milford", label: "Milford" },
	{ value: "noblesville", label: "Noblesville" },
	{ value: "ohio_city", label: "Ohio City" },
	{ value: "over_the_rhine", label: "Over The Rhine (OTR)" },
	{ value: "polaris", label: "Polaris" },
	{ value: "polk_stables", label: "Polk Stables" },
	{ value: "shadyside", label: "The Shadyside" },
	{ value: "southside-works", label: "Southside Works" },
	{ value: "springfield", label: "Springfield" },
	{ value: "st. petersburg", label: "St. Petersburg" },
	{ value: "tarpon springs", label: "Tarpon Springs" },
	{ value: "upper arlingtion", label: "Upper Arlingtion" },
	{ value: "waterfront", label: "Waterfront" },
	{ value: "wesley heights", label: "Wesley Heights" },
	{ value: "west tampa", label: "West Tampa" },
	{ value: "westerville", label: "Westerville" },
	{ value: "worthington-hardware", label: "Worthingtion (Hardware)" },
	{ value: "worthington-library", label: "Worthingtion (Library)" },
	{ value: "zionsville", label: "Zionsville" },
];

export const ID_OFFICENAME = [
	{ value: "606b10f2f7ff2fc987137673", label: "COhatch Beachwood" },
	{ value: "606af961262dad011aa7c154", label: "COhatch Broad Ripple" },
	{ value: "61a4e2f49b30e7104f11278b", label: "COhatch Carmel" },
	{ value: "6109728eb12e145cca3b1f59", label: "COhatch Circle Centre" },
	{ value: "606af88d5e43340c60c209b8", label: "COhatch Delaware" },
	{ value: "606b0d3422b1003de911eea7", label: "COhatch Dublin" },
	{ value: "606af89d5e433411eec209cf", label: "COhatch Easton" },
	{ value: "61a502e6b36e570a87ac582f", label: "COhatch Findlay Market" },
	{ value: "606af857c10359a5c9f3f6ed", label: "COhatch Hyde Park" },
	{ value: "60a1138d775d0f03eec14ee0", label: "COhatch Kenwood" },
	{ value: "61435fba63579c94b09276e5", label: "COhatch Lakeland" },
	{ value: "5d1bcda0dbd6e40010479eec", label: "COhatch Mason" },
	{ value: "606b0ea033fc9a7d5b53a1b7", label: "COhatch Milford" },
	{ value: "606af848e1fae5fe0aa1c2af", label: "COhatch Noblesville" },
	{ value: "5fd23f130ecc6d8a668543db", label: "COhatch Ohio City" },
	{ value: "606af8ab5e4334f28dc209e7", label: "COhatch Polaris" },
	{ value: "624f4116bd2861034d10d844", label: "COhatch Polk Stables" },
	{ value: "6287bdffe608c584f6faecb1", label: "COhatch Southside Works" },
	{ value: "606af872e1fae54e2da1c315", label: "COhatch Springfield" },
	{ value: "61435e994274f6b1e4d2bff8", label: "COhatch St. Petersburg" },
	{ value: "624f40390fd203233bb9b659", label: "COhatch Tarpon Springs" },
	{ value: "606b14899ff4771341cc9201", label: "COhatch The Gateway" },
	{ value: "606af8810b1f05378ad799e7", label: "COhatch Upper Arlington" },
	{ value: "6287bdc9fc1c6944183a059f", label: "COhatch Shadyside" },
	{ value: "6287bd60e608c53fd3fae8d3", label: "COhatch Waterfront" },
	{ value: "61435f4e2a9271b93fb57221", label: "COhatch West Tampa" },
	{ value: "625675b22cec9a850f913a58", label: "COhatch Westerville" },
	{
		value: "606af8e161a5fbfe224c3002",
		label: "COhatch Worthington - The Hardware Store",
	},
	{
		value: "606af8be5e43345791c20a01",
		label: "COhatch Worthington - The Library & The Madery",
	},
	{ value: "61097250b12e1486413b171a", label: "COhatch Zionsville" },
];

export const OFFICEID_NAME = {
	"5d1bcda0dbd6e40010479eec": "COhatch Mason",
	"5fd23f130ecc6d8a668543db": "COhatch Ohio City",
	"606af848e1fae5fe0aa1c2af": "COhatch Noblesville",
	"606af857c10359a5c9f3f6ed": "COhatch Hyde Park",
	"606af872e1fae54e2da1c315": "COhatch Springfield",
	"606af8810b1f05378ad799e7": "COhatch Upper Arlington",
	"606af88d5e43340c60c209b8": "COhatch Delaware",
	"606af89d5e433411eec209cf": "COhatch Easton",
	"606af8ab5e4334f28dc209e7": "COhatch Polaris",
	"606af8be5e43345791c20a01": "COhatch Worthington - The Library & The Madery",
	"606af8e161a5fbfe224c3002": "COhatch Worthington - The Hardware Store",
	"606af961262dad011aa7c154": "COhatch Broad Ripple",
	"606b0d3422b1003de911eea7": "COhatch Dublin",
	"606b0ea033fc9a7d5b53a1b7": "COhatch Milford",
	"606b10f2f7ff2fc987137673": "COhatch Beachwood",
	"606b14899ff4771341cc9201": "COhatch The Gateway",
	"60a1138d775d0f03eec14ee0": "COhatch Kenwood",
	"61097250b12e1486413b171a": "COhatch Zionsville",
	"6109728eb12e145cca3b1f59": "COhatch Circle Centre",
	"61435e994274f6b1e4d2bff8": "COhatch St. Petersburg",
	"61435f4e2a9271b93fb57221": "COhatch West Tampa",
	"61435fba63579c94b09276e5": "COhatch Lakeland",
	"61a4e2f49b30e7104f11278b": "COhatch Carmel",
	"61a502e6b36e570a87ac582f": "COhatch Findlay Market",
	"624f40390fd203233bb9b659": "COhatch Tarpon Springs",
	"624f4116bd2861034d10d844": "COhatch Polk Stables",
	"625675b22cec9a850f913a58": "COhatch Westerville",
	"6287bd60e608c53fd3fae8d3": "COhatch Waterfront",
	"6287bdc9fc1c6944183a059f": "COhatch Shadyside",
	"6287bdffe608c584f6faecb1": "COhatch Southside Works",
};

export const BLOCKED_PAGES = {
	SUPERADMIN: [],
	MASTER_APPROVAL: ["dashboard/technical-support", "dashboard/administrator"],
	MARKET_LEADER: [
		"dashboard/technical-support",
		"dashboard/community-managers",
		"dashboard/administrator",
	],
	COMMUNITY_MANAGER: ["dashboard/technical-support", "dashboard/administrator"],
	TECHNICAL_SUPPORT: [
		"dashboard/community-managers",
		"dashboard/administrator",
	],
	LEADERSHIP: [
		"dashboard/technical-support",
		"dashboard/community-managers",
		"dashboard/administrator",
	],
};

export const BUSINESS_CATEGORY = [
	{ value: "business_services", label: "Business Services" },
	{ value: "home_services", label: "Home + Personal Services" },
	{ value: "shops", label: "Shops" },
	{ value: "makers_and_artisans", label: "Makers + Artisans" },
	{ value: "restaurants", label: "Restaurants" },
	{ value: "community_resources", label: "Community Resources" },
	{ value: "business_resources", label: "Business Resources" },
	{ value: "chambers", label: "Chambers" },
	{ value: "non_profits", label: "Local Nonprofits" },
	{ value: "farmers_market", label: "Farmers + Food " },
];

export const PROPERTY_CATEGORY = [
	{ value: "beach_vacation", label: "Beach Vacation" },
	{ value: "family_homes", label: "Family Homes" },
	{ value: "cabins", label: "Cabins" },
	{ value: "unique_stay", label: "Unique Stay" },
];

export const PROPERTY_BATHROOM_AMENITIES = [
	{
		value: "bathroom-essentials",
		label: "Essentials (towels, soap, toilet paper)",
		icon: GiTowel,
	},
	{ value: "hair-dryer", label: "Hair Dryer" },
	{ value: "shampoo", label: "Shampoo", icon: TbBottle },
	{ value: "conditioner", label: "Conditioner", icon: TbBottle },
	{ value: "body-wash", label: "Body Wash", icon: TbBottle },
	{ value: "body-lotion", label: "Body Lotion", icon: TbBottle },
	{ value: "bathtub", label: "Bathtub", icon: FaBath },
	{ value: "shower", label: "Shower", icon: FaShower },
];

export const PROPERTY_BEDROOM_AMENITIES = [
	{ value: "bed-linens", label: "Bed Linens", icon: BiBlanket },
	{
		value: "blankets-pillows",
		label: "Extra blankets & pillows",
		icon: GiPillow,
	},
	{
		value: "clothing-storage",
		label: "Clothing storage (dresser, closet)",
		icon: BiCloset,
	},
	{ value: "iron", label: "Iron", icon: MdIron },
	{ value: "ironing-board", label: "Ironing Board", icon: MdIron },
	{ value: "hangers", label: "Hangers", icon: TbHanger },
	{ value: "washer", label: "Washer", icon: GiWashingMachine },
	{ value: "dryer", label: "Dryer", icon: MdLocalLaundryService },
	{
		value: "laundry-detergent",
		label: "Laundry Detergent",
		icon: GiSpiralBottle,
	},
];

export const PROPERTY_ENTERTAINMENT_AMENITIES = [
	{ value: "tv", label: "TV", icon: FaTv },
	{ value: "wifi", label: "Wifi", icon: FaWifi },
	{ value: "cable", label: "Cable)", icon: GiCableStayedBridge },
	{ value: "streaming-services", label: "Streaming Services", icon: TbApps },
	{ value: "ping-pong", label: "Ping Pong Table", icon: GiPingPongBat },
	{ value: "pool-table", label: "Pool Table", icon: GiPoolTableCorner },
	{ value: "game-console", label: "Game Console", icon: GiConsoleController },
	{ value: "bluetooth-speaker", label: "Bluetooth Speaker", icon: MdSpeaker },
	{ value: "dedicated-workspace", label: "Dedicated Workspace", icon: GiDesk },
];

export const PROPERTY_KITCHEN_AMENITIES = [
	{
		value: "refridgerator",
		label: "Refridgerator",
		icon: CgSmartHomeRefrigerator,
	},
	{ value: "microwave", label: "Microwave", icon: MdMicrowave },
	{
		value: "silverware",
		label: "Dishes & Silverware (Bowls, plates, cups, forks, etc.)",
		icon: FaUtensils,
	},
	{ value: "dishwasher", label: "Dishwasher", icon: CgSmartHomeWashMachine },
	{ value: "stove", label: "Stove", icon: GiGasStove },
	{ value: "oven", label: "Oven", icon: MdOutlineMicrowave },
	{ value: "coffee-maker", label: "Coffee Maker", icon: MdOutlineCoffeeMaker },
	{ value: "toaster", label: "Toaster", icon: GiToaster },
	{ value: "dining-table", label: "Dining Table", icon: MdDining },
	{
		value: "cooking-pots",
		label: "Cooking Basics (pots & pans, oil, salt & pepper)",
		icon: GiCookingPot,
	},
	{ value: "tea-kettle", label: "Tea Kettle", icon: GiTeapotLeaves },
];

export const PROPERTY_SAFETY_AMENITIES = [
	{ value: "smoke-alarm", label: "Smoke Alarm", icon: RiAlarmWarningFill },
	{
		value: "carbon-monoxide-alarm",
		label: "Carbon Monoxide Alarm",
		icon: RiAlarmWarningFill,
	},
	{
		value: "fire-extinguisher",
		label: "Fire Extinguisher",
		icon: FaFireExtinguisher,
	},
	{ value: "first-aid-kit", label: "First Aid Kit", icon: GiFirstAidKit },
	{ value: "security-cameras", label: "Security Cameras", icon: GiCctvCamera },
	{ value: "gate", label: "Gate", icon: GiGate },
];

export const PROPERTY_COOLING_AMENITIES = [
	{ value: "ac", label: "Central Air Conditioning", icon: GiThermometerCold },
	{ value: "heater", label: "Central Heating", icon: GiThermometerHot },
	{ value: "indoor-fireplace", label: "Indoor Fireplace", icon: GiFireplace },
	{ value: "ceiling-fan", label: "Ceiling fan", icon: FaFan },
];

export const PROPERTY_FAMILY_AMENITIES = [
	{
		value: "childrens-books-toys",
		label: "Children's Books & Toys",
		icon: TbBooks,
	},
	{ value: "crib", label: "Crib", MdCrib },
	{ value: "high-chair", label: "High Chair", icon: GiWoodenChair },
	{ value: "board-games", label: "Board Games", icon: FaBox },
];

export const PROPERTY_PARKING_AMENITIES = [
	{
		value: "premise-parking",
		label: "Free Parking on Premises",
		icon: FaParking,
	},
	{ value: "garage-parking", label: "Garage Parking", icon: GiHomeGarage },
	{
		value: "free-street-parking",
		label: "Free Street Parking",
		icon: FaParking,
	},
	{
		value: "paid-street-parking",
		label: "Paid Street Parking",
		icon: FaParking,
	},
	{ value: "ev-charger", label: "EV Charger", icon: MdElectricCar },
	{ value: "bus-van-parking", label: "Bus/Van Parking", icon: FaParking },
];

export const PROPERTY_OUTDOOR_AMENITIES = [
	{ value: "private-backyard", label: "Private Backyard", icon: GiWoodenDoor },
	{ value: "fenced-yard", label: "Fenced Yard", icon: MdFence },
	{ value: "private-entrance", label: "Private Entrance", icon: GiDoorway },
	{ value: "fire-pit", label: "Fire Pit", icon: IoMdBonfire },
	{ value: "outdoor-furniture", label: "Outdoor Furniture", icon: GiSofa },
	{ value: "bbq-grill", label: "BBQ Grill", icon: MdOutdoorGrill },
	{ value: "gas-grill", label: "Gas Grill", icon: MdOutdoorGrill },
	{ value: "beach-towels", label: "Beach Towels", icon: GiTowel },
	{ value: "beach-umbrellas", label: "Beach Umbrellas", icon: FaUmbrellaBeach },
	{ value: "beach-chairs", label: "Beach Chairs", icons: GrLounge },
	{ value: "private-pool", label: "Private Pool", icon: FaSwimmingPool },
	{ value: "shared-pool", label: "Shared Pool", icon: FaSwimmingPool },
	{ value: "private-hot-tub", label: "Private Hot Tub", icon: FaHotTub },
	{ value: "shared-hot-tub", label: "Shared Hot Tub", icon: FaHotTub },
	{ value: "beach-access", label: "Beach Access", icon: MdBeachAccess },
	{ value: "waterfront", label: "Waterfront", icon: FaWater },
	{ value: "patio", label: "Patio or Balcony", icon: MdBalcony },
	{ value: "mountain-view", label: "Mountain View", icon: GiMountains },
	{ value: "beach-view", label: "Beach View", icon: TbBeach },
	{ value: "lake-view", label: "Lake View", icon: MdWater },
	{ value: "outdoor-shower", label: "Outdoor Shower", icon: GiShower },
];

export const PROPERTY_ACCESSIBILITY_AMENITIES = [
	{ value: "elevator", label: "Elevator", icon: MdElevator },
	{
		value: "wheelchair-accessible",
		label: "Wheelchair Accessible",
		icon: FaWheelchair,
	},
	{
		value: "accessible-parking",
		label: "Accessible parking available",
		icon: FaWheelchair,
	},
];

export const PROPERTY_INFO_FEATURES = [
	{ value: "eventsAllowed", label: "Events Allowed", icon: GiPartyPopper },
	{ value: "petFriendly", label: "Pet Friendly", icon: MdPets },
	{ value: "childrenWelcome", label: "Children Welcome", icon: FaChild },
];

export const WORKSHOP_CATEGORY = [
	{ value: "business_professional", label: "Business and Professional" },
	{ value: "community_culture", label: "Community and Culture" },
	{ value: "drives_donations", label: "Drives + Donations" },
	{ value: "family_education", label: "Family and Education" },
	{ value: "fashion_beauty", label: "Fashion and Beauty" },
	{
		value: "film_media_entertainment",
		label: "Film, Media, and Entertainment",
	},
	{ value: "food_drink", label: "Food and Drink" },
	{ value: "fundraising", label: "Fundraising" },
	{ value: "government_politics", label: "Government and Politics" },
	{ value: "health_wellness", label: "Health and Wellness" },
	{ value: "hobbies", label: "Hobbies and Special Interest" },
	{ value: "home_lifestyle", label: "Home and Lifestyle" },
	{ value: "music", label: "Music" },
	{ value: "performing", label: "Performing and Visual Arts" },
	{ value: "religion_spirituality", label: "Religion and Spirituality" },
	{ value: "school_activities", label: "School Activities" },
	{ value: "science_technology", label: "Science and Technology" },
	{ value: "Seasonal_holidayk", label: "Seasonal and Holiday" },
	{ value: "sports_fitness", label: "Sports and Fitness" },
	{ value: "travel_outdoor", label: "Travel and Outdoor" },
];

