/* eslint-disable */
import React, { useRef } from "react";
import { COHATCH_MARKETS, COHATCH_LOCATIONS, COHATCH_MEETING_ROOMS } from "../../constant";
import html2pdf from "html2pdf.js";

function CCAuthorization({ document }) {
    const DOCUMENT_TYPES_MAP = {
        MEAgreement: "Meeting & Event Agreement",
        HyggeCoworkingAgreement: "Hygge Coworking Agreement",
        CCAuthorization: "Credit Card Authorization",
        COhatchMembershipTerms: "COhatch Membership Agreement"
    };
    const contentRef = useRef(null);


    // Helper function to get the display label
    const getDocumentTypeLabel = (documentType) => DOCUMENT_TYPES_MAP[documentType] || documentType;

    const getMarketLabel = (marketValue) => {
        const market = COHATCH_MARKETS?.market?.find(item => item.value === marketValue);
        return market ? market.label : marketValue;
    };

    const getLocationLabel = (marketValue, locationValue) => {
        // Loop through all cities in COHATCH_LOCATIONS
        for (const city of COHATCH_LOCATIONS.cities) {
            if (city[marketValue]) {  // Check if the city has this market
                const market = city[marketValue];
                const locations = market.locations || []; // Get locations for the market
                const location = locations.find(item => item.value === locationValue);

                if (location) {
                    return location.label;
                } else {
                    console.error(`Location not found for market: ${marketValue}, location: ${locationValue}`);
                    return locationValue; // Fallback to the original value
                }
            }
        }
        return marketValue; // Fallback if market is not found
    };

    const getMeetingRoomLabel = (marketValue, locationValue, roomValue) => {
        const rooms = COHATCH_MEETING_ROOMS?.[marketValue]?.[locationValue] || [];
        const room = rooms.find(item => item.value === roomValue);
        return room ? room.label : roomValue;
    };

    const formatTime = (timeString) => {
        const timeArray = timeString.split(":");
        let hours = parseInt(timeArray[0]);
        const minutes = timeArray[1];
        const period = hours >= 12 ? "PM" : "AM";

        // Convert 24-hour time to 12-hour format
        hours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

        // Return formatted time
        return `${hours}:${minutes} ${period}`;
    };

    const termsContent = [
        {
            header: `PAYMENT + CANCELLATION POLICY`,
            content: [
                `Payment is due upon booking and can be made by card or ACH. Credit card charges will be subject to a 3% service fee.
                        Reservations are refundable/changeable up to two weeks prior to the meeting. If changes need to be made within 2 weeks of
                        your meeting, the invoice becomes non-refundable. A full credit will be applied to a future booking.`,
            ]
        },
        {
            header: "",
            content: [
                `COhatch is a weapons free environment. No person, member, guest, or employee is permitted to possess or carry a firearm in
                        facilities operated or managed by COhatch, unless otherwise approved by COhatch's third party safety team (Safe Passage
                        Consulting). Subsequently, open carry of any weapons are otherwise prohibited unless they are uniformed on-duty law
                        enforcement.`,
            ]
        },

    ];



    const downloadPDF = () => {
        const element = contentRef.current;
        if (element) {
            const options = {
                margin: 0.1,
                filename: "document.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: {
                    scale: 4,
                    useCORS: true,
                    onerror: (err) => {
                        console.error("Error loading images: ", err);
                        alert("An error occurred while loading images. Please check the console.");
                    },
                },
                jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            };

            html2pdf().from(element).set(options).save();
        } else {
            console.error("Element not found");
        }
    };


    return (
        <div>
            <div id="authorization-content" className="bg-white shadow-lg rounded-lg w-full max-w-7xl p-6 mb-4" ref={contentRef}>
                <div className="items-center">
                    <div className="">
                        <img className="mx-auto mt-4 mb-4 h-auto w-36" src={"https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/static/images/COhatch%20Logo%20Horizontal%20w_tag%20green.png"} alt=""></img>
                    </div>
                </div>
                <h2 className="text-4xl font-semibold mb-6 text-center">{getDocumentTypeLabel(document.documentType)}</h2>
                <div className="space-y-6 mb-6">
                    <div className="text-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-success-800">
                        <div className="flex flex-col items-center">
                            <h3 className="font-semibold text-lg">Meeting Location:</h3>
                            {document?.meetingLocation ? (
                                <p className="text-center">
                                    {getMarketLabel(document.meetingLocation.market)} - {" "}
                                    {getLocationLabel(document.meetingLocation.market, document.meetingLocation.location)} - {" "}
                                    {getMeetingRoomLabel(document.meetingLocation.market, document.meetingLocation.location, document.meetingLocation.room)}
                                </p>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </div>

                        <div className="flex flex-col items-center">
                            <h3 className="font-semibold text-lg">Meeting Time:</h3>
                            {document.meetingStart && document.meetingEnd ? (
                                <>
                                    <p>
                                        {formatTime(document.meetingStart)} - {formatTime(document.meetingEnd)}
                                    </p>
                                </>
                            ) : (
                                <p>Invalid Meeting Time</p>
                            )}
                        </div>

                        <div className="flex flex-col items-center">
                            <h3 className="font-semibold text-lg">Meeting Date:</h3>
                            <p>{new Date(document.meetingDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}</p>
                        </div>

                        <div className="flex flex-col items-center">
                            <h3 className="font-semibold text-lg">Rate:</h3>
                            <p>${document.rate}</p>
                        </div>

                        <div className="flex flex-col items-center">
                            <h3 className="font-semibold text-lg">Company Name:</h3>
                            <p>{document.companyName}</p>
                        </div>

                        <div className="flex flex-col items-center">
                            <h3 className="font-semibold text-lg">Primary Phone:</h3>
                            <p>{document.primaryPhone}</p>
                        </div>
                    </div>

                    <div className="space-y-4">
                        {termsContent.map((term, index) => (
                            <div key={index} className="bg-gray-50 p-4 rounded-md shadow-sm">
                                <h4 className="font-semibold text-lg text-success-800">{term.header}</h4>
                                {term.content.map((item, idx) => {
                                    if (Array.isArray(item)) {
                                        return (
                                            <ul key={idx} className="list-disc pl-6">
                                                {item.map((point, pIdx) => (
                                                    <li key={pIdx} className="text-success-800">{point}</li>
                                                ))}
                                            </ul>
                                        );
                                    } else {
                                        return <p key={idx} className="text-success-800">{item}</p>;
                                    }
                                })}
                            </div>
                        ))}
                    </div>

                    <div className="mx-auto p-2">
                        {/* Check if both credit card and bank info are missing */}
                        {!(document?.creditCard?.cardholderName || document?.bankInfo?.bankName) ? (
                            <div className="max-w-4xl mx-auto p-4 bg-gray-50 rounded-lg shadow-md">
                                {/* Payment Information Title */}
                                <div className="text-center">
                                    <h3 className="text-2xl font-semibold text-danger-500">
                                        PAYMENT INFORMATION - Not yet submitted by the user
                                    </h3>
                                </div>
                            </div>
                        ) : (
                            <>
                                {/* Displaying Credit Card Data if available */}
                                {document.creditCard?.cardholderName ? (
                                    <div className="max-w-4xl mx-auto p-8 bg-gray-50 rounded-lg shadow-md mb-6">
                                        {/* Credit Card Information Title */}
                                        <div className="text-center mb-6">
                                            <h3 className="text-2xl font-semibold text-primary-400 flex items-center justify-center gap-2">
                                                Credit Card Information
                                            </h3>
                                        </div>

                                        {/* Cardholder Name and Card Number */}
                                        <div className="text-center flex flex-col md:flex-row gap-6 mb-6">
                                            <div className="flex-1">
                                                <h4 className="text-lg font-semibold text-gray-700">Cardholder Name</h4>
                                                <p className="text-success-800">
                                                    {document.creditCard.cardholderName || 'Data not available yet'}
                                                </p>
                                            </div>

                                            <div className="flex-1">
                                                <h4 className="text-lg font-semibold text-gray-700">Card Number</h4>
                                                <p className="text-success-800">
                                                    {document.creditCard.cardNumber || 'Data not available yet'}
                                                </p>
                                            </div>
                                        </div>

                                        {/* Expiration Date, CVV, and ZIP Code */}
                                        <div className="text-center flex flex-wrap gap-6 mb-6">
                                            <div className="flex-1">
                                                <h4 className="text-lg font-semibold text-gray-700">Expiration Date</h4>
                                                <p className="text-success-800">
                                                    {document.creditCard.expirationDate || 'Data not available yet'}
                                                </p>
                                            </div>
                                            <div className="flex-1">
                                                <h4 className="text-lg font-semibold text-gray-700">CVV</h4>
                                                <p className="text-success-800">
                                                    {document.creditCard.cvv || 'Data not available yet'}
                                                </p>
                                            </div>
                                            <div className="flex-1">
                                                <h4 className="text-lg font-semibold text-gray-700">ZIP Code</h4>
                                                <p className="text-success-800">
                                                    {document.creditCard.zipCode || 'Data not available yet'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}

                                {/* Displaying Bank Info if available */}
                                {document?.bankInfo?.bankName ? (
                                    <div className="max-w-4xl mx-auto p-8 bg-gray-50 rounded-lg shadow-md mb-6">
                                        {/* Bank Information Title */}
                                        <div className="text-center mb-6">
                                            <h3 className="text-2xl font-semibold text-primary-400 flex items-center justify-center gap-2">
                                                Bank Information
                                            </h3>
                                        </div>

                                        {/* Bank Name and Account Info */}
                                        <div className="text-center flex flex-wrap gap-6 mb-6">
                                            <div className="flex-1">
                                                <h4 className="text-lg font-semibold text-gray-700">Bank Name</h4>
                                                <p className="text-success-800">
                                                    {document.bankInfo.bankName || 'Data not available yet'}
                                                </p>
                                            </div>
                                            <div className="flex-1">
                                                <h4 className="text-lg font-semibold text-gray-700">Routing Number</h4>
                                                <p className="text-success-800">
                                                    {document.bankInfo.routingNumber || 'Data not available yet'}
                                                </p>
                                            </div>
                                            <div className="flex-1">
                                                <h4 className="text-lg font-semibold text-gray-700">Account Number</h4>
                                                <p className="text-success-800">
                                                    {document.bankInfo.accountNumber || 'Data not available yet'}
                                                </p>
                                            </div>
                                        </div>

                                        {/* Account Type and Owner Info */}
                                        <div className="text-center flex flex-col md:flex-row gap-6 mb-6">
                                            <div className="flex-1">
                                                <h4 className="text-lg font-semibold text-gray-700">Account Type</h4>
                                                <p className="text-success-800">
                                                    {document.bankInfo.accountType || 'Data not available yet'}
                                                </p>
                                            </div>
                                            <div className="flex-1">
                                                <h4 className="text-lg font-semibold text-gray-700">Account Owner</h4>
                                                <p className="text-success-800">
                                                    {document.bankInfo.accountOwner || 'Data not available yet'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        )}
                    </div>

                    <div className="flex flex-col sm:flex-row sm:gap-8 justify-center mt-2">
                        {/* Signature Canvas Section */}
                        <div className="flex-1 sm:w-[55%]">
                            {document?.signature ? (
                                <div className="max-w-lg mx-auto p-2 bg-gray-50 rounded-lg shadow-md">
                                    <h4 className="text-2xl font-semibold text-center text-primary-400 mb-4">Signature Preview</h4>
                                    <div className="flex justify-center items-center p-4 bg-white border border-gray-200 rounded-lg">
                                        <img
                                            src={document.signature}
                                            alt="Signature Preview"
                                            className="max-w-full h-auto rounded-md"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="max-w-4xl mx-auto p-4 bg-gray-50 rounded-lg shadow-md">
                                    <div className="text-center">
                                        <h3 className="text-2xl font-semibold text-danger-500">
                                            SIGNATURE - Not yet submitted by user.
                                        </h3>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Download Button */}

            </div>
            <div className="mt-2 text-center">
                <button onClick={downloadPDF} className="mt-4 bg-primary-400 text-white py-2 px-4 rounded">
                    Download PDF
                </button>
            </div>
        </div>
    );
}

export default CCAuthorization;
